import React, { useEffect, useState } from 'react';
import './_plans-filter.scss';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import selectors from './plansFilterSelectors';
import { setPlansFilters } from './plansFilterActions';
import resources from '../../features/Shared/resources';
import plansFilterResources from './plansFilterResources';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import SelectedPlan from './SelectedPlan/SelectedPlan';
import MuiButton from '../controls/MuiButton';

function PlansFilter({
    plansInitial,
    onFilterChange,
    showFilter,
    checkedPlans,
    setPlansFilters,
    showSelected,
    isListView,
    searchInputId,
    listBoxId,
    plansFilterId }) {
    const classPrefix = 'filter-box';
    const [plans, setPlans] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (!checkedPlans || checkedPlans.size === 0) {
            setPlansFilters(plansInitial ? plansInitial.toJS() : []);
        }
    }, [plansInitial]);

    useEffect(() => {
        setPlans(checkedPlans && checkedPlans.size > 0 ? checkedPlans.toJS() : []);
    }, [checkedPlans]);

    const toggleCheckboxValue = (id) => {
        const updatedList = plans.map((plan) => {
            if (plan.id !== id) {
                return plan;
            }
            const newChecked = (plan.programNames && plan.programNames.some(program => program.checked)) ? false : !plan.checked;
            const updatedProgramNames = (plan.programNames && plan.programNames.some(program => program.checked))
                ? plan.programNames.map(program => ({ ...program, checked: false }))
                : plan.programNames;
            return { ...plan, programNames: updatedProgramNames, checked: newChecked };
        });

        setPlans(updatedList);
        return updatedList;
    };

    const toggleChildCheckboxValue = (id, subId) => {
        const updatedList = plans.map((plan) => {
            if (plan.id !== id) {
                return plan;
            }

            const updatedProgramNames = plan.programNames
                .map(program => (program.id === subId ? { ...program, checked: !program.checked } : program));
            const newChecked = (updatedProgramNames && updatedProgramNames.some(program => program.checked));
            return { ...plan, programNames: updatedProgramNames, checked: newChecked };
        });

        setPlans(updatedList);
        return updatedList;
    };

    const resetFilter = () => {
        const plansReset = plansInitial ? plansInitial.toJS() : [];
        setPlansFilters(plansReset);
        if (onFilterChange) {
            onFilterChange(plansReset);
        }
    };

    const applyFilter = () => {
        setPlansFilters(plans);
        if (onFilterChange) {
            onFilterChange(plans);
        }
    };

    const removeItem = (plan) => {
        let newPlans;

        if (plan.programNames) {
            const ids = plan.programNames.filter(program => program.checked).map(program => program.id);
            ids.forEach((program) => {
                newPlans = toggleChildCheckboxValue(plan.id, program);
            });
        }
        newPlans = toggleCheckboxValue(plan.id);

        setPlansFilters(newPlans);
        if (onFilterChange) {
            onFilterChange(newPlans);
        }
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const morePlans = () => plans && plans.filter(plan => plan.checked
        || (plan.programNames && plan.programNames.some(program => program.checked))).length > 3;

    const filtersHasChanged =
        (plans && plans.some(plan => plan.checked
            || (plan.programNames && plan.programNames.some(program => program.checked)))
        ) || (checkedPlans
            && checkedPlans.toJS().some(plan => plan.checked
                || (plan.programNames && plan.programNames.some(program => program.checked))));

    return <>
        {showFilter && <div id={plansFilterId} className={classNames([`${classPrefix}__selection`])}>
            <div className="results-count sr-only" aria-live="assertive">${plans ? `${plans.length} results found` : 'No results found'} </div>
            <div className={classNames([`${classPrefix}__title-container`])}>
                <h4 className={classNames([`${classPrefix}__title`])}>
                    {`${plansFilterResources.filterPlansTitle} (${plans ? plans.length : 0})`}
                </h4>
            </div>
            <ul id={listBoxId} className={classNames([`${classPrefix}__plan-option`])} role="listbox" aria-labelledby={searchInputId}>
                <fieldset>
                    <legend className="sr-only">Select Health Programs</legend>
                    {plans && plans.filter(plan => plan.name).map(plan => (
                        <li id={`li_plan_id_${plan.id.toString()}`} key={`plan_item_${plan.id.toString()}`} className={classNames([`${classPrefix}__list-item`])} role="option" aria-selected="false">
                            <FormControlLabel
                                key={`plan_${plan.id.toString()}`}
                                control={
                                    <Checkbox
                                        id={`plan_${plan.id.toString()}`}
                                        className={classNames([`${classPrefix}__control-field`])}
                                        checked={plan.checked
                                            || (plan.programNames && plan.programNames.length > 0
                                                && plan.programNames.every(program => program.checked))
                                        }
                                        size="medium"
                                        onChange={() => toggleCheckboxValue(plan.id)}
                                        indeterminate={plan.programNames && plan.programNames.length > 0
                                            && plan.programNames.some(program => program.checked)
                                            && plan.programNames.some(program => !program.checked)}
                                    />
                                }
                                label={plan.name}
                            />

                            {plan.programNames && plan.programNames.some(programName => programName.name)
                                && <ul className={classNames([`${classPrefix}__item-option`])}>
                                    {plan.programNames.filter(programName => programName.name).map(programName => (
                                        <li key={`program_item_${programName.id.toString()}`}>
                                            <FormControlLabel
                                                key={`program_${programName.id.toString()}`}
                                                control={
                                                    <Checkbox
                                                        id={`program_${programName.id.toString()}`}
                                                        size="small"
                                                        className={classNames([`${classPrefix}__control-field`])}
                                                        checked={programName.checked}
                                                        onChange={() => toggleChildCheckboxValue(plan.id, programName.id)}
                                                        label={programName.name}
                                                    />
                                                }
                                                label={programName.name}
                                            />
                                        </li>
                                    ))}
                                </ul>}
                        </li>
                    )
                    )}
                </fieldset>
            </ul>
            <div className={classNames('grid__row', [`${classPrefix}__action-buttons`])}>
                <div className={classNames('col d-flex float-left', [`${classPrefix}__action-button-item`])}>
                    <MuiButton
                        type="button"
                        size="small"
                        theme="new btn_new--green"
                        className="btn_new--squared"
                        onClick={applyFilter}
                        disabled={!filtersHasChanged}
                    >
                        {resources.applyButtonText}
                    </MuiButton>
                </div>
                <div className={classNames('col d-flex float-left', [`${classPrefix}__action-button-item`])}>
                    <MuiButton
                        type="button"
                        size="small"
                        theme="new btn_new--white"
                        className="btn_new--squared"
                        onClick={resetFilter}
                        disabled={!filtersHasChanged}
                    >
                        {resources.resetButtonText}
                    </MuiButton>
                </div>

            </div>
        </div>}
        {
            showSelected && !showFilter
            && checkedPlans && checkedPlans.size > 0
            && checkedPlans.toJS().some(plan => plan.checked || (plan.programNames && plan.programNames.some(program => program.checked)))
            && <div className={classNames([`${classPrefix}__selected`, { 'hidden-on-mobile': !isListView }])}>
                <div>
                    {
                        checkedPlans.toJS().filter(plan => plan.checked || (plan.programNames && plan.programNames.some(program => program.checked)))
                            .slice(0, morePlans() && !showMore ? 3 : plans.length)
                            .map(plan =>
                                <SelectedPlan
                                    key={`plan-selected_${plan.id.toString()}`}
                                    plan={plan}
                                    onRemove={removeItem}
                                />
                            )
                    }
                    {morePlans()
                        && <span className="show-button" onClick={toggleShowMore}>
                            {showMore ? resources.showLessButtonText : resources.showMoreButtonText}
                        </span>}
                </div>
                <div role="button" className="reset-button" onClick={resetFilter}>
                    {resources.resetButtonText}
                </div>

            </div >
        }
    </>;
}

PlansFilter.propTypes = {
    plansInitial: PropTypes.object,
    checkedPlans: PropTypes.object,
    onFilterChange: PropTypes.func,
    showFilter: PropTypes.bool.isRequired,
    setPlansFilters: PropTypes.func.isRequired,
    showSelected: PropTypes.bool,
    isListView: PropTypes.bool,
    searchInputId: PropTypes.string,
    listBoxId: PropTypes.string,
    plansFilterId: PropTypes.string,
};

const actions = {
    setPlansFilters
};

export default connect(selectors, actions)(PlansFilter);