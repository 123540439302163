import React from 'react';
import PropTypes from 'prop-types';
import ReactConfirmAlert from 'react-confirm-alert';
import { CustomPopupBody } from './CustomPopupBody';

function CustomPopup({ showPopup, body, title, onCancel, isLoading, subtitle, closeOnClickOutside, cancelButtonId }) {
    return showPopup
        ? <ReactConfirmAlert
            closeOnClickOutside={closeOnClickOutside}
            onClickOutside={closeOnClickOutside ? onCancel : () => {}}
            customUI={() =>
                <CustomPopupBody
                    cancelButtonId={cancelButtonId}
                    title={title}
                    body={body}
                    onCancel={onCancel}
                    isLoading={isLoading}
                    subtitle={subtitle}
                />}
          />
        : <></>;
}

CustomPopup.propTypes = {
    showPopup: PropTypes.bool.isRequired,
    body: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    subtitle: PropTypes.string,
    closeOnClickOutside: PropTypes.bool,
    cancelButtonId: PropTypes.string
};

export default CustomPopup;