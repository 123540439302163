import {
    SET_BILLING_INFO,
    UPDATE_BANK_ACCOUNT
} from './billingInfoTypes';
import { fromJS } from 'immutable';
import { initialState } from './billingInfoInitialState';
import { bankOperationTypesModelNamesMap } from '../../../enums/bankOperationTypes';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_BILLING_INFO:
            return state.set('billingInfo', fromJS(action.payload.billingInfo));
        case UPDATE_BANK_ACCOUNT: {
            const bankingInfo = action.payload.bankAccount;
            const specificBankingInfoIndex =  state.get('billingInfo')
                .findIndex(specificBankingInfo => specificBankingInfo.get('memberGuid') === bankingInfo.memberGuid);
            return state.setIn(['billingInfo', specificBankingInfoIndex, bankOperationTypesModelNamesMap[bankingInfo.bankAccount.operationTypeID]], fromJS(bankingInfo.bankAccount));
        }
        default:
            return state;
    }
};