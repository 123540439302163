import * as types from './partnerGroupMemberhipsPaymentsTypes';
import * as partnerGroupMemberhipsPaymentsService from './partnerGroupMemberhipsPaymentsService';
import * as periodsService from '../../Shared/Periods/periodsService';
import * as loadingActions from '../../Loading/loadingActions';

const setPayments = (payments) => {
    return { type: types.SET_PAYMENTS, payments };
};

function getPayments(programPaymentTypeId, partnerId, periodIDFrom, periodIDTo) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return partnerGroupMemberhipsPaymentsService
            .getPartnerPayments(programPaymentTypeId, partnerId, periodIDFrom, periodIDTo)
            .then((payments) => {
                dispatch(setPayments(payments));
                dispatch(setInitialPeriodsRange({ periodIDFrom, periodIDTo }));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS));
            });
    };
}

function downloadPayments(partnerId, periodIDFrom, periodIDTo) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_PAYMENTS));
        return partnerGroupMemberhipsPaymentsService
            .dowloadPartnerPayments(partnerId, periodIDFrom, periodIDTo)
            .then(() => {
                dispatch(setInitialPeriodsRange({ periodIDFrom, periodIDTo }));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_PAYMENTS)));
    };
}

const setInitialPeriodsRange = (periods) => {
    return { type: types.SET_INITIAL_PERIODS_RANGE, periods };
};

const setUsagePeriods = (usagePeriods) => {
    return { type: types.SET_USAGE_PERIODS, usagePeriods };
};

export function getClosedUsagePeriods() {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_USAGE_PERIODS));
        return periodsService
            .getClosedUsagePeriods()
            .then((closedUsagePeriods) => {
                dispatch(setUsagePeriods(closedUsagePeriods));
                dispatch(setInitialPeriodsRange({ periodIDFrom: closedUsagePeriods[0].id, periodIDTo: closedUsagePeriods[0].id }));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_USAGE_PERIODS));
            });
    };
}

export function getPeriodsUntilCurrent() {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_USAGE_PERIODS));
        return periodsService
            .getPeriodsUntilCurrent()
            .then((periods) => {
                dispatch(setUsagePeriods(periods));
                dispatch(setInitialPeriodsRange(periods[0]));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_USAGE_PERIODS));
            });
    };
};

function getProgramPaymentTypeID(partnerID) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_PROGRAM_PAYMENT_TYPE_ID));
        return partnerGroupMemberhipsPaymentsService
            .getProgramPaymentTypeID(partnerID)
            .then((programPaymentTypeID) => {
                dispatch(setProgramPaymentTypeID(programPaymentTypeID));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PROGRAM_PAYMENT_TYPE_ID));
            });
    };
}

const setProgramPaymentTypeID = (programPaymentTypeID) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE_ID, programPaymentTypeID };
};

export default {
    getClosedUsagePeriods,
    getPayments,
    downloadPayments,
    getPeriodsUntilCurrent,
    getProgramPaymentTypeID
};
