const loginResources = {
    loginButtonText: 'Log in',
    linkForgotUsername: 'Forgot username?',
    linkForgotPassword: 'Forgot password?',
    facilitySignupButtonId: 'facilitySignupButtonId',
    facilitySignupButtonText: 'facility signup',
    memberSignupButtonText: 'member signup',
    labelUsernameOrEmail: 'Username or Email',
    labelPassword: 'Password',
    title: 'Log in',
    fieldNameUserName: 'userName',
    fieldNamePassword: 'password',
    loginDataForm: 'loginDataForm',
    signupText: 'Don’t have an account yet?',
    isLoginPopupOpenParamName: 'isLoginPopupOpen',
    errorParamName: 'error',
    redirectUrlParamName: 'redirectUrl',
    memberSignupButtonId: 'memberSignupButtonId',
    cancelButtonId: 'cancelButtonId',
    loginButtonId: 'loginButtonId'
};
export default loginResources;