import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import BankingInfoResources from '../BankingInfo/bankingInfoResources';
import BankAccountSecurityNote from './BankAccountSecurityNote';
import MuiInputCheckbox from '../../../components/controls/MuiInputCheckbox';
import BankAccountDetails from './BankAccountDetails';

function BankAccount({ buttons,
    description,
    bankAccountModelName,
    checkboxLabel,
    setBankAccountInfo,
    errorMessage
}) {
    const [isCopyBankAccountChecked, setCopyBankAccountValue] = useState(false);
    useEffect(() => {
        if (isCopyBankAccountChecked) {
            setBankAccountInfo();
        }
    }, [isCopyBankAccountChecked]);
    const onChangeHandler = (event) => {
        setCopyBankAccountValue(event.target.checked);
    };
    const prefix = bankAccountModelName ? `${bankAccountModelName}.` : '';
    return <div>
        {errorMessage && <div className="grid__row">
            <div className="col-md-12">
                <span className="bank-info-text bank-info-text__error">{errorMessage}</span>
            </div>
        </div>}
        {description && <div className="grid__row">
            <div className="col-md-12">
                <span className="bank-info-text">{description}</span>
            </div>
        </div>}
        {setBankAccountInfo &&
            <Field
                id="is-copied-bank-account-info"
                label={checkboxLabel || BankingInfoResources.checkboxLabel}
                name={`${prefix}${BankingInfoResources.fieldCheckbox}`}
                checked={isCopyBankAccountChecked}
                component={MuiInputCheckbox}
                onChange={onChangeHandler}
            />}
        <BankAccountSecurityNote />
        <BankAccountDetails buttons={buttons} bankAccountModelName={bankAccountModelName} isCopyBankAccountChecked={isCopyBankAccountChecked} />
    </div>;
}

BankAccount.propTypes = {
    payeeToken: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    retrieveBankAccountDetails: PropTypes.func,
    buttons: PropTypes.object,
    description: PropTypes.string,
    operationTypeID: PropTypes.number,
    setBankAccountInfo: PropTypes.func,
    bankAccountModelName: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string,
    errorMessage: PropTypes.string
};

export default BankAccount;