import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, Select, FormControl, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './_muiinput.scss';
import styles from './_muiinput.module.scss';

const classes = {
    root: {
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                'border-color': 'transparent'
            }
        }
    },
};

function MuiInputSelect(props) {
    const {
        meta = {},
        input = {},
        label,
        addAsteriskToLabel,
        variant,
        defaultValue,
        options,
        id,
        labelId,
        getOptionLabel,
        getOptionValue,
        disabled,
        fullWidth,
        placeholder,
        readOnly,
        iconComponent,
        classes,
        hideValidationMessage
    } = props;
    useEffect(() => {
        setTouched(meta.touched);
    }, [meta.touched]);
    const muiLabel = useMemo(() => {
        return addAsteriskToLabel ? `${label} *` : label;
    }, [addAsteriskToLabel]);
    const [touched, setTouched] = useState(false);
    const { value, onChange, name } = input;
    const { error } = meta;
    const [open, setOpen] = useState(false);
    const showError = error && touched;
    const handleClose = () => {
        setOpen(false);
        setTouched(true);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return <div className="mui-input-container">
        <FormControl
            error={showError}
            disabled={disabled}
            fullWidth={fullWidth}
            className={classes.root}
        >
            <InputLabel id={labelId} variant={variant} className={readOnly ? styles.label_readOnly : ''}>{muiLabel}</InputLabel>
            <Select
                name={name}
                variant={readOnly ? 'filled' : variant}
                labelId={labelId}
                id={id}
                value={value}
                label={muiLabel}
                defaultValue={defaultValue}
                onChange={onChange}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                IconComponent={readOnly ? () => null : iconComponent}
                className={readOnly ? styles.input_readOnly : ''}
                classes={{
                    select: disabled ? styles.select_input_disabled : '',
                    icon: disabled ? styles.select_icon_disabled : ''
                }}
                inputProps={{
                    readOnly,
                    className: readOnly ? styles.input_readOnly : ''
                }}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    classes: { paper: styles.paper }
                }}
            >
                {placeholder !== undefined && (
                    <MenuItem value=" " key="-1" selected disabled>
                        <em>{placeholder}</em>
                    </MenuItem>
                )}
                {options.map(option => (
                    <MenuItem
                        key={getOptionValue(option)}
                        value={getOptionValue(option)}
                    >
                        {getOptionLabel(option)}
                    </MenuItem>
                ))}
            </Select>
            {!hideValidationMessage && showError && (
                <FormHelperText>{error}</FormHelperText>
            )}
        </FormControl>
    </div>;
}

MuiInputSelect.defaultProps = {
    addAsteriskToLabel: false,
    variant: 'outlined',
    disabled: false,
    fullWidth: true,
    readOnly: false
};

MuiInputSelect.propTypes = {
    labelId: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    addAsteriskToLabel: PropTypes.bool,
    meta: PropTypes.object,
    input: PropTypes.object,
    variant: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    iconComponent: PropTypes.elementType,
    classes: PropTypes.object,
    hideValidationMessage: PropTypes.bool
};

export default withStyles(classes)(MuiInputSelect);