import { createSelector } from 'reselect';
import { GET_REIMBURSEMENTS_GROUP_LIST  } from './reimbursementTypes';
import reimbursementResources from './reimbursementResources';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_REIMBURSEMENTS_GROUP_LIST)
        .size;
    return size > 0;
};
export const getReimbursementsGroups = state => state.membershipReimbursementReducer.get('reimbursementGroups');
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

const getPeriods = state => state.periodsReducer.get('closedUsagePeriods');
const getDefaultPeriodIDFrom = createSelector([getPeriods], (periods) => {
    if (periods.size === 0) return undefined;
    return periods.first().get('id');
});
const getDefaultPeriodIDTo = createSelector([getPeriods], (periods) => {
    if (periods.size === 0) return undefined;
    if (periods.size >= reimbursementResources.defaultMonthCount) {
        return periods.get(reimbursementResources.defaultMonthCount - 1).get('id');
    }
    return periods.last().get('id');
});

export default createSelector(
    [
        getIsLoading,
        getReimbursementsGroups,
        getSelectedMemberGroup,
        getDefaultPeriodIDTo,
        getDefaultPeriodIDFrom,
        getPeriods
    ],
    (
        isLoading,
        reimbursementsGroups,
        selectedMemberGroup,
        defaultPeriodIDFrom,
        defaultPeriodIDTo,
        periods
    ) => ({
        isLoading,
        reimbursementsGroups,
        selectedMemberGroup,
        defaultPeriodIDFrom,
        defaultPeriodIDTo,
        periods
    })
);