export default (values, props) => {
    const errors = {};
    if (values.get('periodIDFrom') > values.get('periodIDTo')) {
        const periodRangeErrorText = 'End month should be greater than start month.';

        errors.periodIDFrom = periodRangeErrorText;
        errors.periodIDTo = periodRangeErrorText;

        errors._error = periodRangeErrorText;
    }

    return errors;
};