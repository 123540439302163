import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

function BenefitsSectionItem({ icon, description }) {
    return <li className="benefits-section__item">
        <MaterialIcon
            className="benefits-section__icon"
            icon={icon}
            ariaHidden
        />
        <div className="benefits-section__text">
            {description}
        </div>
    </li>;
}

BenefitsSectionItem.propTypes = {
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default BenefitsSectionItem;