import { fromJS } from 'immutable';
import * as types from './reimbursementTypes';
import { initialState } from './reimbursementInitials';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_REIMBURSEMENTS_GROUP_LIST: {
            return state.set('reimbursementGroups', fromJS(action.reimbursements));
        }
        case types.SET_REIMBURSEMENTS_GROUP: {
            return state.updateIn(['reimbursementGroups'], reimbursementGroups =>
                reimbursementGroups.map((reimbursementGroup) => {
                    return reimbursementGroup.get('planOrProgramID') === action.planReimbursementsGroup.planOrProgramID
                        && reimbursementGroup.get('enrollmentEntity') === action.planReimbursementsGroup.enrollmentEntity
                        ? fromJS(action.planReimbursementsGroup)
                        : reimbursementGroup;
                })
            );
        }
        case types.SET_REIMBURSEMENTS_ITEMS: {
            return state.updateIn(['reimbursementGroups'], reimbursementGroups =>
                reimbursementGroups.map((reimbursementGroup) => {
                    return reimbursementGroup.get('planOrProgramID') === action.planReimbursementsGroup.planOrProgramID
                        && reimbursementGroup.get('enrollmentEntity') === action.planReimbursementsGroup.enrollmentEntity
                        ? reimbursementGroup.setIn(['reimbursements', 'items'], reimbursementGroup.getIn(['reimbursements', 'items'])
                            .concat(fromJS(action.planReimbursementsGroup.reimbursements.items)))
                        : reimbursementGroup;
                })
            );
        }
        default:
            return state;
    }
}