const bankOperationTypes = Object.freeze({
    Credit: 2,
    Debit: 3
});

export const bankOperationTypesNamesMap = Object.freeze({
    [bankOperationTypes.Credit]: 'Deposit',
    [bankOperationTypes.Debit]: 'Withdrawal'
});

export const bankOperationTypesModelNamesMap = Object.freeze({
    [bankOperationTypes.Credit]: 'depositToBankAccount',
    [bankOperationTypes.Debit]: 'withdrawalFromBankAccount'
});

export default bankOperationTypes;
