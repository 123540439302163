import { createSelector } from 'reselect';
import { GET_BILLING_INFO, UPDATE_BANK_ACCOUNT } from './billingInfoTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_BILLING_INFO || i === UPDATE_BANK_ACCOUNT)
        .size;
    return size > 0;
};
const getBillingInfo = state => state.accountBillingInfoReducer.get('billingInfo');
const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

export default createSelector(
    [
        getIsLoading,
        getBillingInfo,
        getSelectedMemberGroup,
    ],
    (
        isLoading,
        billingInfo,
        selectedMemberGroup,
    ) => ({
        isLoading,
        billingInfo,
        selectedMemberGroup,
    })
);