import React from 'react';
import PropTypes from 'prop-types';
import MuiInfoBox from '../../../components/InfoBox/MuiInfoBox';
import BankAccount from '../../Shared/BankingInfo/BankAccount';
import FormGroup from '../../../components/controls/FormGroup';
import MuiButton from '../../../components/controls/MuiButton';
import { reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import { bankOperationTypesNamesMap } from '../../../enums/bankOperationTypes';
import billingInfoResources from './billingInfoResources';
import { updateBankAccount } from './billingInfoActions';
import { connect } from 'react-redux';
import styles from './_billing_info.module.scss';

function BankAccountForm({ handleSubmit,
    description,
    pristine,
    submitting,
    operationTypeID,
    updateBankAccount,
    initialValues,
    setBankAccountInfo }) {
    const updateBankAccountHandler = (formData) => {
        const data = formData.toJS();
        data.bankAccount.operationTypeID = operationTypeID;
        updateBankAccount(data);
    };
    const bankInfoRequiredError = !initialValues.get(billingInfoResources.bankAccountModelName);
    return <FormGroup onSubmit={handleSubmit(updateBankAccountHandler)}>
        <MuiInfoBox
            title={`${bankOperationTypesNamesMap[operationTypeID]} ${billingInfoResources.title}`}
            className={bankInfoRequiredError ? styles.billing_info__error : 'height-auto'}
            bordered
            expandable
        >
            <BankAccount
                setBankAccountInfo={setBankAccountInfo}
                bankAccountModelName={billingInfoResources.bankAccountModelName}
                checkboxLabel={billingInfoResources.bankAccountCheckboxLabel}
                initialValues={initialValues}
                errorMessage={bankInfoRequiredError ? `${billingInfoResources.billingInfoRequiredText} ${description}` : null}
                buttons={
                    <MuiButton className="btn_new btn_new--green"
                        disabled={pristine || submitting}
                        type="submit"
                    >
                        {billingInfoResources.updateButtonText}
                    </MuiButton>}
                description={bankInfoRequiredError ? null : description}
            />
        </MuiInfoBox>
    </FormGroup>;
}

BankAccountForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    operationTypeID: PropTypes.number,
    onFormSubmit: PropTypes.func,
    setBankAccountInfo: PropTypes.func,
    description: PropTypes.string,
    updateBankAccount: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired
};

const connectStateToProps = connect(null, { updateBankAccount });

export default reduxForm({
    ...defaultFormProperties
})(connectStateToProps(BankAccountForm));
