import React, { useEffect } from 'react';
import SectionsDivider from '../../../../components/SectionsDivider/SectionsDivider';
import SolutionsSectionItem from '../../../../components/SolutionsSection/SolutionsSectionItem';
import SolutionsSection from '../../../../components/SolutionsSection/SolutionsSection';
import BenefitsSection from '../../../../components/BenefitsSection/BenefitsSection';
import BenefitsSectionItem from '../../../../components/BenefitsSection/BenefitsSectionItems';
import TextSection from '../../../../components/TextSection/TextSection';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import CallToActionImage from '../../../../components/CallToAction/CallToActionImage';
import guestAreaSharedResources from '../guestAreaSharedResources';
import TestimonialSection from '../../../../components/TestimonialSection/TestimonialSection';
import TestimonialSectionItem from '../../../../components/TestimonialSection/TestimonialSectionItem';
import optum from '../../../../assets/images/optum.svg';
import fitonhealth from '../../../../assets/images/FitOn-Health-logo.png';
import { API_PATHS } from '../../../../http/configs';
import ash from '../../../../assets/images/ash.svg';
import PropTypes from 'prop-types';
import ContactUsSection from '../ContactUs/ContactUsSection';
import '../../../../styles/_public-page.scss';
import { OPTUM_ALT_TAG, FITON_HEALTH_ALT_TAG, ASH_ALT_TAG } from '../../../../constants/partnerImageGroups';
import MetaData from '../../../../components/MetaData/MetaData';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';
import resources from './forEmployersResources';

function ForEmployers({ history }) {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle(resources.title);
    }, []);
    function joinUsHandler() {
        window.location = API_PATHS.calendlyUrl;
    };
    return <div className="public-page">
        <MetaData keywords={resources.title}
            title={`${resources.title}, ${HEALTHY_CONTRIBUTIONS_NAME}, Call: ${HC_PHONE_NUMBER}`}
            description={`${HEALTHY_CONTRIBUTIONS_NAME} offers innovative corporate wellness programs and benefits to promote employee engagement, productivity, and healthier lifestyles`}
        />
        <CallToAction>
            <CallToActionBody
                title={<>Corporate Wellness Programs designed to empower your employees</>}
                description={<>For employers, see how Healthy Contributions helps engage and activate members to participate in wellness programs and reward healthy behaviors to change lives for better health.<br />Transform your workplace and increase productivity with a flexible, secure, and HIPPA compliant wellness solution from Healthy Contributions.</>}
                buttonText={guestAreaSharedResources.requestDemoButtonText}
                buttonClickHandler={joinUsHandler}
            />
            <CallToActionImage image="fitnessHr"
                imageAlt={guestAreaSharedResources.fitnessHrImageAlt}
                imageClassName="fitness-hr"
            />
        </CallToAction>
        <SectionsDivider />
        <TextSection title={<>For employers, help your employees manage their health <span className="green">to manage your costs</span></>}
            text="Create a Healthier Workplace by Engaging Your Employees With Corporate Fitness Programs."
            theme="column-on-large-screen"
        />
        <SectionsDivider />
        <BenefitsSection header={<>Benefits of having <span className="green">a wellness program</span></>}
            description="Leverage rewards and incentives to activate your employees to participate in wellness programs that positively shape their health outcomes, while simultaneously boosting productivity and lowering healthcare costs."
        >
            <BenefitsSectionItem description="Reduce average health costs by approximately 25%" icon="local_offer" />
            <BenefitsSectionItem description="Increase  employee engagement, retention, and recruitment" icon="analytics" />
            <BenefitsSectionItem description="Increase employee productivity an average of $353 per person" icon="group_add" />
        </BenefitsSection>
        <SectionsDivider />
        <SolutionsSection
            header={<>Customized corporate health and wellness solutions for <span className="green">employers</span></>}
        >
            <SolutionsSectionItem materialIconName="card_giftcard" header="Anytime Fitness group memberships"
                description="Employers can subsidize the full membership, partial or a set amount."
                linkPath="https://www.anytimefitness.com/employee-wellness/"
                isExternalLink
            />
            <SolutionsSectionItem materialIconName="settings_backup_restore" header="Reimbursement Programs"
                description="Employees enroll in a participating fitness facility and pay regular dues.  Employees receive a reimbursement when they hit a pre-determined number of visits per month."
            />
            <SolutionsSectionItem materialIconName="confirmation_number" header="Wellness Vouchers"
                description="Contribute a set amount towards employees’ wellness, redeemable for any product and/or service at any US based partnering fitness facility."
            />
            <SolutionsSectionItem materialIconName="star_half" header="Physical Fitness Assessment"
                description="Healthy Contributions customizes and coordinates assessments at  convenient locations for candidates needing to pass a physical assessment.  "
            />
        </SolutionsSection>
        <SectionsDivider />
        <TestimonialSection header={<>Employer <span className="green">testimonials</span></>}>
            <TestimonialSectionItem
                description="Optum has worked with Healthy Contributions since 2010 to serve as a payment processor for our network locations. The Healthy Contributions team that supports us has been flexible and reliable as our products have evolved and grown. They have enhanced their platform based on our business needs and from direct gym and fitness location feedback. I have had the pleasure of working closely with many of the support staff and they have been true partners that are responsive, kind, and dependable."
                logoName={optum}
                logoAlt={OPTUM_ALT_TAG}
                name="Vince Pozinski"
                position="Sr. Product Director at Optum"
            />
            <TestimonialSectionItem name="Kate Bosse"
                position="Director, Network Management"
                description="They truly take a partnership approach and deliver high quality service which in turn allows our members to have the best experience possible."
                logoName={fitonhealth}
                logoAlt={FITON_HEALTH_ALT_TAG}
            />
            <TestimonialSectionItem name="Jeff Na"
                position="Vice President of Fitness"
                logoName={ash}
                logoAlt={ASH_ALT_TAG}
                description="We have thoroughly enjoyed working with Health Contributions as they continue to be supportive and communicative with our Anytime Fitness partnership."
            />
        </TestimonialSection>
        <SectionsDivider />
        <ContactUsSection
            history={history}
            title={'Prioritize Health and Wellness at Work With Tailored Fitness Benefits Your Team Will Love'}
        >
            <CallToActionImage image={'healthScreen'}
                imageAlt={guestAreaSharedResources.firefighterImageAlt}
                imageClassName={'health-screen'}
            />
        </ContactUsSection>
        <SectionsDivider />
    </div>;
}

ForEmployers.propTypes = {
    history: PropTypes.object.isRequired
};

export default ForEmployers;