import React, { useEffect } from 'react';
import SolutionsSectionItem from '../../../../components/SolutionsSection/SolutionsSectionItem';
import SolutionsSection from '../../../../components/SolutionsSection/SolutionsSection';
import SectionsDivider from '../../../../components/SectionsDivider/SectionsDivider';
import QuotesSection from '../../../../components/QuotesSection/QuotesSection';
import BenefitsSection from '../../../../components/BenefitsSection/BenefitsSection';
import BenefitsSectionItem from '../../../../components/BenefitsSection/BenefitsSectionItems';
import PartnersSection from '../../../../components/PartnerSection/PartnersSection';
import PartnersSectionItem from '../../../../components/PartnerSection/PartnersSectionItems';
import TextSection from '../../../../components/TextSection/TextSection';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import guestAreaSharedResources from '../guestAreaSharedResources';
import { API_PATHS } from '../../../../http/configs';
import PropTypes from 'prop-types';
import ContactUsSection from '../ContactUs/ContactUsSection';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import CallToActionImage from '../../../../components/CallToAction/CallToActionImage';
import { MEDICAL_PARTNER_IMAGES } from '../../../../constants/partnerImageGroups';
import MetaData from '../../../../components/MetaData/MetaData';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import '../../../../styles/_public-page.scss';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';
import resources from './aboutUsResources';

function AboutUs({ history }) {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle(resources.title);
    }, []);
    function joinUsHandler() {
        window.location = API_PATHS.calendlyUrl;
    };
    return <div className="public-page">
        <MetaData keywords="about us"
            title={`${resources.title}, ${HEALTHY_CONTRIBUTIONS_NAME}, Call: ${HC_PHONE_NUMBER}`}
            description={`${HEALTHY_CONTRIBUTIONS_NAME} helps employers, health insurers and fitness facilities engage members and reward healthy behaviors`}
        />
        <CallToAction>
            <CallToActionBody
                title={<>We make wellness easy</>}
                description={<>{guestAreaSharedResources.requestDemoDescriptionText}<br />Unlock your potential with a tailored wellness solution.</>}
                buttonText={guestAreaSharedResources.requestDemoButtonText} buttonClickHandler={joinUsHandler}
            />
            <CallToActionImage image="womanDaughterSwim"
                imageAlt={guestAreaSharedResources.motherDaughterSwimImageAlt}
                imageClassName="woman-daughter-swim"
            />
        </CallToAction>
        <SectionsDivider />
        <TextSection title={<>About <span className="green">Healthy Contributions</span></>}
            text="We create and manage wellness programs for small-to-mid-sized businesses, health insurance providers, benefit networks, and more. No other provider combines both wellness and data processing expertise, resulting in superior solutions that help people live healthier lives."
        />
        <SectionsDivider />
        <BenefitsSection header={<>We make it <span className="green">happen</span></>}
            description="At Healthy Contributions, we provide customized wellness solutions for organizations and their people. Work with our knowledgeable professionals who specialize in designing effective programs to help organizations and their people achieve their health goals."
        >
            <BenefitsSectionItem description="People first" icon="groups" />
            <BenefitsSectionItem description="Tailored solutions" icon="emoji_objects" />
            <BenefitsSectionItem description="Devoted to excellence" icon="insights" />
            <BenefitsSectionItem description="Flexible pricing" icon="loyalty" />
            <BenefitsSectionItem description="Service-driven" icon="check_circle_outline" />
            <BenefitsSectionItem description="Secure Platform" icon="beenhere" />
        </BenefitsSection>
        <SectionsDivider />
        <PartnersSection header={<>Join our growing wellness network</>}>
            {MEDICAL_PARTNER_IMAGES.map((imageInfo, index) =>
                <PartnersSectionItem key={index}
                    alt={imageInfo.alt}
                    imageObject={imageInfo.imageName}
                />
            )
            }
        </PartnersSection>
        <SectionsDivider />
        <SolutionsSection header={<>Wellness solutions for <span className="green">Everyone</span></>}
            description="Healthy Contributions has helped over 20,000+ fitness facilities and wellness partners create, implement, and sustain wellness programs that help people live healthier lives."
            headerTheme="column-on-large-screen"
        >
            <SolutionsSectionItem materialIconName="business_center" header="Wellness data and payment management and processing services" />
            <SolutionsSectionItem materialIconName="business" header="Fitness and health management services" />
            <SolutionsSectionItem materialIconName="health_and_safety" header="Health screening and assessment services" />
            <SolutionsSectionItem materialIconName="groups" header="Nutrition and dietary services" />
        </SolutionsSection>
        <SectionsDivider />
        <QuotesSection header={<>Dedicated <span className="green">Account Management</span></>}
            description="Get expert support. Lean on a dedicated account manager to help guide your wellness program from the start. Our team of professionals will monitor program engagement and rewards to make your wellness efforts are a success."
            text="I enjoy my position at Healthy Contributions as I get to develop relationships with our new and existing partners! I am fulfilled everyday helping wellness partners achieve their company’s wellness goals for their employees and members."
            signature="Josie C., Senior Account Manager, Healthy Contributions"
        />
        <SectionsDivider />
        <ContactUsSection
            history={history}
            title={'Keep members in healthy company with wellness programs customized by Healthy Contributions'}
        >
            <CallToActionImage image={'floatieBlue'}
                imageAlt={guestAreaSharedResources.tubeFloatingImageAlt}
                imageClassName={'floatie-blue'}
            />
        </ContactUsSection>
        <SectionsDivider />
    </div>;
}

AboutUs.propTypes = {
    history: PropTypes.object.isRequired
};

export default AboutUs;