const paymentTypes = Object.freeze({
    reimbursements: 1,
    facilityFees: 2,
    gmFacilityPayments: 3,
    gmPartnerFees: 4,
    gmPartnerDues: 5,
    gmMemberDues: 6,
    gmMemberReimbursements: 7
});
export const partnerPortionPaymentTypeNamesMap = Object.freeze({
    [paymentTypes.gmPartnerFees]: 'Fees',
    [paymentTypes.gmPartnerDues]: 'Dues'
});
export const memberPortionPaymentTypeNamesMap = Object.freeze({
    [paymentTypes.gmMemberDues]: 'Dues'
});
export default paymentTypes;
