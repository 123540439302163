import React from 'react';
import PropTypes from 'prop-types';
import './_partner-section.scss';

function PartnersSection({ header, children }) {
    return <div className="partners-section">
        <h2 className="partners-section__header">{header}</h2>
        <ul className="partners-section__items">
            {children}
        </ul>
    </div>;
}

PartnersSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default PartnersSection;