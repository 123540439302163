import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import selectors from './selectors';
import { doChecks } from './actions';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import Spinner from '../../components/Spinner/Spinner';
import ConditionalRedirect from '../../routing/conditionalRedirect';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';

function AccountInfo({ filteredRoutes, currentRoute, isBankingRequired, isReimbursementEnabled, match, isLoading, selectedMemberGroup, doChecks, history }) {
    useEffect(() => {
        if (selectedMemberGroup) {
            doChecks(selectedMemberGroup.membersIds);
        }
    }, [selectedMemberGroup]);
    return isLoading ?
        (
            <div className="account-info">
                <div className="grid__row">
                    <Spinner />
                </div>
            </div>
        )
        : (
            <div className="account-info">
                <h2 className="layout__title">Memberships</h2>
                <NavigationTabControl
                    tabs={filteredRoutes}
                    onNavigate={history.push}
                />
                <Switch>
                    {filteredRoutes.map((route, i) => (
                        <CustomRoute
                            key={i}
                            path={route.path}
                            currentRoute={route}
                            filteredRoutes={filteredRoutes}
                        />
                    ))}

                    <ConditionalRedirect
                        condition={isBankingRequired !== undefined || isReimbursementEnabled !== undefined}
                        fromRoute={currentRoute}
                        toRoute={filteredRoutes[0]}
                    />
                </Switch>
            </div>
        );
}

AccountInfo.propTypes = {
    isBankingRequired: PropTypes.bool,
    isReimbursementEnabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    filteredRoutes: PropTypes.arrayOf(routeType),
    currentRoute: routeType,
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    history: PropTypes.object.isRequired,
    doChecks: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
};

export default connect(selectors, { doChecks })(AccountInfo);
