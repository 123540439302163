import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getReimbursementsGroupList(memberGuids, usagePeriodIDFrom, usagePeriodIDTo) {
    return http.get(API_PATHS.reimbursementHistory, { params: { memberGuids, usagePeriodIDFrom, usagePeriodIDTo } });
}

export function getPlanReimbursementsGroup(planReimbursementsGroup, pageNumber, pageSize, sortBy, sortOrder) {
    const { memberGuids, planOrProgramID, usagePeriodIDFrom, usagePeriodIDTo, enrollmentEntity } = planReimbursementsGroup;
    return http.get(replaceRouteParams(API_PATHS.planReimbursementsGroup, enrollmentEntity, planOrProgramID), { params: { memberGuids, usagePeriodIDFrom, usagePeriodIDTo, page: pageNumber, pageSize, sortBy, sortOrder } });
}