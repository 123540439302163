import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import InfoBox from '../../../components/InfoBox/InfoBox';
import ConnectedConfirmPopup from '../../../components/Popups/ConfirmAlert/ConnectedConfirmPopup';
import Spinner from '../../../components/Spinner/Spinner';
import PaymentViaACHForm from '../PaymentViaACHForm/PaymentViaACHForm';
import PaymentConfirmationForm from '../PaymentConfirmationForm/PaymentConfirmationForm';
import selectors from './partnerPaymentWithdrawalSectionSelectors';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import actions from './partnerPaymentWithdrawalSectionActions';
import { getClosedUsagePeriods } from '../../Shared/Periods/periodsActions';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import partnerBillingTypes from '../../../enums/partnerBillingTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';

function PartnerPaymentWithdrawalSection({
    isLoading,
    programTypes,
    defaultProgramType,
    setProgramType,
    programPaymentTypes,
    defaultProgramPaymentType,
    setProgramPaymentType,
    partners,
    periods,
    paymentWithdrawal,
    getPartnersListByProgramPaymentType,
    sendPartnerPaymentsWithdrawal,
    downloadPaymentWithdrawal,
    checkPartnerPaymentsWithdrawal,
    popupMessage,
    popupSubmitText,
    isAchUploadAvailable,
    achUploadErrorMessage,
    programPaymentTypeId,
    programTypeId,
    getClosedUsagePeriods,
    defaultPaymentType,
    paymentTypes,
    setPaymentType
}) {
    const { gmrAddProgram } = useFlags();
    useEffect(() => {
        setProgramPaymentType(defaultProgramPaymentType);
        setProgramType(defaultProgramType);
        setPaymentType(defaultPaymentType);
    }, []);
    useEffect(() => {
        if (programPaymentTypeId) {
            const partnerBillingType = gmrAddProgram ? partnerBillingTypes.DirectFromBankAccount : null;
            getPartnersListByProgramPaymentType(programPaymentTypeId, partnerBillingType);
            if (programPaymentTypeId === programPaymentTypesEnum.partnerPaid ||
                programPaymentTypeId === programPaymentTypesEnum.memberPaid
            ) {
                getClosedUsagePeriods();
            }
        }
    }, [programPaymentTypeId]);
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const handlePINSubmit = (payment) => {
        const paymentToSend = {
            programTypeID: payment.getIn(['programType', 'id']),
            programPaymentTypeID: payment.getIn(['programPaymentType', 'id']),
            partnerID: payment.getIn(['partner', 'id']),
            periodID: payment.getIn(['period', 'id']),
            pin: payment.get('pin')
        };
        sendPartnerPaymentsWithdrawal(paymentToSend);
        setIsPopupOpened(false);
    };
    const [paymentWithdrawalWithPIN, setPaymentWithdrawal] = useState(paymentWithdrawal);
    const handleSubmitSend = (payment) => {
        const programTypeId = payment.getIn(['programType', 'id']);
        const programPaymentTypeId = payment.getIn(['programPaymentType', 'id']);
        const partnerId = payment.getIn(['partner', 'id']);
        const periodId = payment.getIn(['period', 'id']);
        setPaymentWithdrawal(payment);
        checkPartnerPaymentsWithdrawal(programTypeId, programPaymentTypeId, partnerId, periodId).then(() => {
            setIsPopupOpened(true);
        });
    };
    const handleSubmitDownload = (payment) => {
        const paymentToDownload = {
            programTypeId: payment.getIn(['programType', 'id']),
            programPaymentTypeId: payment.getIn(['programPaymentType', 'id']),
            partnerID: payment.getIn(['partner', 'id']),
            periodID: payment.getIn(['period', 'id']),
        };
        downloadPaymentWithdrawal(paymentToDownload);
    };
    const paymentWithdrawalConfirmationForm = 'paymentWithdrawalConfirmationForm';
    const paymentWithdrawalViaACHForm = 'paymentWithdrawalViaACHForm';
    return <InfoBox title={wellnessProgramPaymentResources.titlePartnerPaymentWithdrawalViaACHFiles} bordered>
        {isLoading
            ? <Spinner />
            : <></>
        }
        <ConnectedConfirmPopup
            showPopup={isPopupOpened}
            title={wellnessProgramPaymentResources.titleConfirmACHUpload}
            body={
                <PaymentConfirmationForm
                    message={popupMessage}
                    form={paymentWithdrawalConfirmationForm}
                    initialValues={paymentWithdrawalWithPIN}
                    onSubmit={handlePINSubmit}
                />}
            onConfirm={(dispatch) => {
                dispatch(submit(paymentWithdrawalConfirmationForm));
            }}
            onCancel={() => setIsPopupOpened(false)}
            labelOk={popupSubmitText}
            labelCancel={wellnessProgramPaymentResources.buttonCancel}
        />
        <PaymentViaACHForm
            gmrAddProgram={gmrAddProgram}
            form={paymentWithdrawalViaACHForm}
            programTypes={programTypes}
            programPaymentTypes={programPaymentTypes}
            partners={partners}
            periods={periods}
            onSubmitSend={handleSubmitSend}
            onSubmitDownload={handleSubmitDownload}
            initialValues={paymentWithdrawal}
            isSendDisabled={!isAchUploadAvailable}
            sendErrorMessage={achUploadErrorMessage}
            paymentTypes={paymentTypes}
            paymentTypeFilterVisible
            programPaymentTypeFilterDisabled
        />
    </InfoBox>;
}

PartnerPaymentWithdrawalSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    setProgramType: PropTypes.func.isRequired,
    setProgramPaymentType: PropTypes.func.isRequired,
    periods: PropTypes.object,
    partners: PropTypes.object.isRequired,
    paymentWithdrawal: PropTypes.object.isRequired,
    getPartnersListByProgramType: PropTypes.func.isRequired,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired,
    sendPartnerPaymentsWithdrawal: PropTypes.func.isRequired,
    downloadPaymentWithdrawal: PropTypes.func.isRequired,
    checkPartnerPaymentsWithdrawal: PropTypes.func.isRequired,
    defaultProgramType: PropTypes.object,
    defaultProgramPaymentType: PropTypes.object,
    popupMessage: PropTypes.string,
    popupSubmitText: PropTypes.string,
    isAchUploadAvailable: PropTypes.bool.isRequired,
    achUploadErrorMessage: PropTypes.string,
    programPaymentTypeId: PropTypes.number,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    programTypeId: PropTypes.number,
    setPaymentType: PropTypes.func.isRequired,
    defaultPaymentType: PropTypes.object,
    paymentTypes: PropTypes.object,
};

const mapDispatchToProps = {
    getClosedUsagePeriods,
    ...actions
};

export default connect(selectors, mapDispatchToProps)(PartnerPaymentWithdrawalSection);