import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsForgotPasswordPopupOpen } from './forgotPasswordActions';
import CustomPopup from '../Popups/CustomPopup/CustomPopup';
import ForgotPasswordForm from './ForgotPasswordForm';
import forgotPasswordResources from './forgotPasswordResources';
import selectors from './forgotPasswordSelectors';
import { useLocation } from 'react-router-dom';

function ForgotPasswordPopup({ isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen, isLoading }) {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const showPopup = queryParams.get(forgotPasswordResources.paramName);

    const sendButtonRef = useRef();
    const userNameTextBoxRef = useRef();
    const captchaRef = useRef();

    const handleKeyPress = useCallback((e) => {
        const firstFocusableElement = document.getElementById(forgotPasswordResources.cancelButtonId);
        let lastFocusableElement = sendButtonRef.current;

        if (sendButtonRef.current && sendButtonRef.current.disabled) {
            lastFocusableElement = userNameTextBoxRef.current;
        }

        if (e.target && firstFocusableElement && e.target === firstFocusableElement && e.key === 'Tab' && e.shiftKey) {
            e.preventDefault();

            if (lastFocusableElement) {
                lastFocusableElement.focus();
            }
        } else if (e.target && lastFocusableElement && e.target === lastFocusableElement && e.key === 'Tab' && !e.shiftKey) {
            e.preventDefault();

            if (firstFocusableElement) {
                firstFocusableElement.focus();
            }
        }
    }, []);

    const handleClose = () => {
        document.getElementById(forgotPasswordResources.loginButtonId).focus();

        setIsForgotPasswordPopupOpen(false);
    };

    useEffect(() => {
        window.addEventListener('keyup', handleKeyPress);

        return () => { window.removeEventListener('keyup', handleKeyPress); };
    }, []);

    useEffect(() => {
        if (showPopup) {
            setIsForgotPasswordPopupOpen(showPopup);
        }
    }, [showPopup]);

    return <CustomPopup
        cancelButtonId={forgotPasswordResources.cancelButtonId}
        isLoading={isLoading}
        showPopup={isForgotPasswordPopupOpen}
        onCancel={handleClose}
        title={forgotPasswordResources.title}
        subtitle={forgotPasswordResources.subtitle}
        body={<ForgotPasswordForm sendButtonRef={sendButtonRef} userNameTextBoxRef={userNameTextBoxRef} captchaRef={captchaRef} closePopup={() => setIsForgotPasswordPopupOpen(false)} />}
           />;
}

ForgotPasswordPopup.propTypes = {
    isForgotPasswordPopupOpen: PropTypes.bool.isRequired,
    setIsForgotPasswordPopupOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    captchaRef: PropTypes.object
};

const mapDispatchToProps = {
    setIsForgotPasswordPopupOpen
};

const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(ForgotPasswordPopup);