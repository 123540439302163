import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner/Spinner';
import selectors from './facilityEnrollmentPageSelectors';
import { enrollIntoAFFacilityWithinProgram } from './facilityEnrollmentPageActions';
import { getFacility } from '../../../Facilities/facilityActions';
import LegalContract from '../LegalContract/LegalContract';
import routes from '../../../../routing/routes';
import { hasActiveFacilityEnrollments } from '../../../MemberArea/memberAreaActions';
import bankOperationTypes from '../../../../enums/bankOperationTypes';

function FacilityEnrollmentPage({
    history,
    match,
    isLoading,
    enrollIntoAFFacilityWithinProgram,
    getFacility,
    membersIds,
    facilityDescription,
    facilityId,
    hasActiveFacilityEnrollments,
    facilityAFNumber }) {
    useEffect(() => {
        getFacility(match.params.facilityId);
    }, [match.params.facilityId]);
    const handleSubmit = (legalcontractmodel) => {
        const membersaffacilitywithinprogramenrollment = legalcontractmodel.toJS();
        membersaffacilitywithinprogramenrollment.facilityId = facilityId;
        membersaffacilitywithinprogramenrollment.MemberIds = membersIds;
        membersaffacilitywithinprogramenrollment.OperationTypeID = bankOperationTypes.Debit;
        return enrollIntoAFFacilityWithinProgram(membersaffacilitywithinprogramenrollment)
            .then(() => {
                hasActiveFacilityEnrollments(membersIds).then(() => {
                    history.push(routes.facilityInformation.path);
                });
            });
    };
    return isLoading
        ? <Spinner />
        : <div>
            <LegalContract handleSubmit={handleSubmit} memberIdGuid={membersIds[0]}
                facilityDescription={facilityDescription} facilityAFNumber={facilityAFNumber} readOnly={false} history={history}
            />
        </div>;
}

FacilityEnrollmentPage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    enrollIntoAFFacilityWithinProgram: PropTypes.func.isRequired,
    getFacility: PropTypes.func.isRequired,
    membersIds: PropTypes.array.isRequired,
    hasActiveFacilityEnrollments: PropTypes.func.isRequired,
    facilityDescription: PropTypes.string,
    facilityId: PropTypes.number,
    facilityAFNumber: PropTypes.string
};

const mapDispatchToProps = {
    enrollIntoAFFacilityWithinProgram,
    getFacility,
    hasActiveFacilityEnrollments
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(FacilityEnrollmentPage);