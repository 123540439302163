import { createSelector } from 'reselect';
import allRoutes from '../../routing/routes';

export const getSelectedMemberGroup = state =>
    state.app.get('selectedMemberGroup');

export const getIsLoading = state => state.accountInfo.get('isLoading');
export const getIsBankingRequired = (state, props) =>
    state.accountInfo.get('isBankingRequired');
export const getIsReimbursementEnabled = (state, props) =>
    state.accountInfo.get('isReimbursementEnabled');
export const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

const selectFilteredRoutes = createSelector(
    [getIsBankingRequired, getIsReimbursementEnabled, getRoutes],
    (isBankingRequired, isReimbursementEnabled, routes) => {
        return routes.filter(
            route => (isBankingRequired || route.path !== allRoutes.billing.path) && (isReimbursementEnabled || route.path !== allRoutes.reimbursement.path)
        );
    }
);

export default createSelector(
    [
        getSelectedMemberGroup,
        getIsLoading,
        getIsBankingRequired,
        getIsReimbursementEnabled,
        selectFilteredRoutes,
    ],
    (selectedMemberGroup, isLoading, isBankingRequired, isReimbursementEnabled, filteredRoutes) => ({
        selectedMemberGroup,
        isLoading,
        isBankingRequired,
        isReimbursementEnabled,
        filteredRoutes,
    })
);
