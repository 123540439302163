import { createSelector } from 'reselect';
import { GET_REIMBURSEMENTS_GROUP  } from '../reimbursementTypes';

const getState = state => state;
const getIndex = (state, index) => index;
const getIsLoading = createSelector([getState, getIndex], (state, index) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === `${GET_REIMBURSEMENTS_GROUP}_${index}`)
        .size;
    return size > 0;
});

export default getIsLoading;