import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import BankingInfoResources, { BankAccountTypeOptions } from './bankingInfoResources';
import { MuiRadioGroup } from '../../../components/controls/MuiRadioGroup';
import * as bankingValidationRules from './validate';

function BankAccountDetails({ buttons,
    bankAccountModelName,
    isCopyBankAccountChecked
}) {
    const prefix = bankAccountModelName ? `${bankAccountModelName}.` : '';
    return <div>
        <div className="grid__row">
            <div className="col-md-6">
                <Field
                    id={BankingInfoResources.fieldRoutingNumber}
                    name={`${prefix}${BankingInfoResources.fieldRoutingNumber}`}
                    component={MuiInputText}
                    label={BankingInfoResources.routingNumberLabel}
                    disabled={isCopyBankAccountChecked}
                    validate={isCopyBankAccountChecked
                        ? []
                        : [
                            bankingValidationRules.routingNumberRequired,
                            bankingValidationRules.routingNumberNumeric,
                            bankingValidationRules.routingNumberLength,
                            bankingValidationRules.routingNumberSpecialCombination
                        ]}
                />
            </div>
            <div className="col-md-6">
                <Field
                    id={BankingInfoResources.fieldAccountNumber}
                    name={`${prefix}${BankingInfoResources.fieldAccountNumber}`}
                    component={MuiInputText}
                    label={BankingInfoResources.accountNumberLabel}
                    disabled={isCopyBankAccountChecked}
                    validate={isCopyBankAccountChecked
                        ? []
                        : [
                            bankingValidationRules.accountNumberRequired,
                            bankingValidationRules.accountNumberNumericWithWildcard,
                            bankingValidationRules.accountNumberLength
                        ]}
                />
            </div>
        </div>
        <div className="grid__row">
            <div className="col-md-12">
                <Field
                    component={MuiRadioGroup}
                    label={BankingInfoResources.accountTypeLabel}
                    name={`${prefix}${BankingInfoResources.fieldBankAccountType}`}
                    options={BankAccountTypeOptions}
                    disabled={isCopyBankAccountChecked}
                    validate={isCopyBankAccountChecked ? [] : [bankingValidationRules.accountTypeRequired]}
                />
            </div>
        </div>
        {buttons && <div className="grid__row">
            {buttons}
        </div>}
    </div>;
}

BankAccountDetails.propTypes = {
    buttons: PropTypes.object,
    bankAccountModelName: PropTypes.string.isRequired,
    isCopyBankAccountChecked: PropTypes.bool.isRequired
};

export default BankAccountDetails;