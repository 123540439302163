import React from 'react';
import classNames from 'classnames';

function Button({ id, theme, label, size, isMaxWidth, keepLabelCase, icon, className, buttonRef, ...props }) {
    return (
        <button
            id={id}
            ref={buttonRef}
            className={classNames([
                { 'btn': theme || !className },
                { 'btn_blue': !theme && !className },
                { [`btn_${theme}`]: theme },
                { 'btn_medium': !theme && !className },
                { [`btn_${size}`]: theme && size },
                { 'btn_max-width': isMaxWidth },
                { 'btn_original-case': keepLabelCase },
                { [className]: className }])} {...props}
        >{props.children}</button>);
}

Button.defaultProps = {
    size: 'medium',
    isMaxWidth: false,
    keepLabelCase: false,
    className: ''
};

export default Button;
