import config from './configs';
import AppService from '../features/App/service';
import UsageLogService from '../features/MemberProfile/UsageLog/service';
import FacilityInfoService from '../features/MemberProfile/FacilityInfo/FacilitiesSection/service';
import GeneralInfoService from '../features/MemberProfile/GeneralInfo/service';
import AccountInfoService from '../features/AccountInfo/service';
import ChangePasswordService from '../features/Shared/ChangePassword/changePasswordService';
import InsuranceInfoService from '../features/AccountInfo/InsuranceInfo/service';
import * as PartnersListService from '../features/Partners/PartnersList/partnersListService';
import * as ManagePartnerService from '../features/Shared/Partners/managePartnerService';
import * as StatesService from '../features/States/stateService';
import * as ManagePartnerManagerService from '../features/Shared/PartnerManager/managePartnerManagerService';
import * as ProgramTypeService from '../features/ProgramTypes/programTypeService';

export default {
    AppService,
    UsageLogService: UsageLogService(config),
    GeneralInfoService: GeneralInfoService(config),
    AccountInfoService: AccountInfoService(config),
    FacilityInfoService: FacilityInfoService(config),
    ChangePasswordService,
    InsuranceInfoService: InsuranceInfoService(config),
    PartnersListService,
    ManagePartnerService,
    StatesService,
    ManagePartnerManagerService,
    ProgramTypeService
};
