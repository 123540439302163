import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import { GET_PAYMENTS, GET_PARTNERS, CHECK_PAYMENTS_PROCESSED, SEND_PAYMENTS } from './memberPaymentsTypes';
import { GET_PERIODS_UNTIL_CURRENT } from '../../Shared/Periods/periodsTypes';
import resources from './memberPaymentsResources';
import { MONTH_YEAR } from '../../../constants/formats';
import programPaymentTypes from '../../../enums/programPaymentTypes';
import currencyFormatter from '../../../utilities/formatters/currencyFormatter';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_PAYMENTS || i === GET_PARTNERS || i === GET_PERIODS_UNTIL_CURRENT || i === CHECK_PAYMENTS_PROCESSED || i === SEND_PAYMENTS
    ).size;

    return size > 0;
};

const getProgramPaymentTypes = (state) => {
    return state.programPaymentTypeReducer.get('programPaymentTypes').filter(ppt => ppt.get('id') !== programPaymentTypes.partnerPaid);
};
const getMemberPaymentTypes = state => state.memberPaymentTypeReducer.get('memberPaymentTypes');
const getPartners = state => state.memberPaymentsReducer.get('partners');
const getPeriods = state => state.periodsReducer.get('periodsUntilCurrent');
const getMemberPayments = state => state.memberPaymentsReducer.get('payments');
const getTotalPaymentsAmount = createSelector([getMemberPayments], (payments) => {
    let total = 0;
    if (payments.size !== 0) {
        total = payments.toJS().reduce((acc, obj) => acc + obj.amountToBeSent, 0);
    }
    return currencyFormatter.formatToUSDollar(total);
});
const getMemberPaymentsCount = createSelector([getMemberPayments], (payments) => {
    return payments.size;
});
const getInitialFilterValues = state => state.memberPaymentsReducer.get('filters');
const getDefaultPeriodID = createSelector([getPeriods], (periods) => {
    if (periods.size === 0) return undefined;
    const currentPeriod = periods.find(x => x.get('yearMonth') === moment().format(MONTH_YEAR));
    if (currentPeriod) {
        return currentPeriod.toJS().id;
    }
    return periods.first().toJS().id;
});
const formSelector = formValueSelector(resources.memberPaymentsFiltersFormName, state => state.form.toJS());
const getProgramTypeID = state => formSelector(state, resources.fieldProgramTypeID);
const getPaymentTypeID = state => formSelector(state, resources.fieldPaymentTypeID);
const getPartnerID = state => formSelector(state, resources.fieldPartnerID);
const getPeriodID = state => formSelector(state, resources.fieldPeriodID);
const getIsViewButtonDisabled = createSelector([getProgramTypeID, getPaymentTypeID, getPartnerID, getPeriodID], (programTypeID, paymentTypeID, partnerID, periodID) => {
    return !programTypeID || !paymentTypeID || !partnerID || !periodID;
});
const getPopupData = state => state.memberPaymentsReducer.get('popupData');
const getPopupMessage = state => state.memberPaymentsReducer.getIn(['popupData', 'message']);
const getSubmitText = state => state.memberPaymentsReducer.getIn(['popupData', 'submitText']);

export default createSelector(
    [
        getIsLoading,
        getMemberPayments,
        getTotalPaymentsAmount,
        getMemberPaymentsCount,
        getProgramPaymentTypes,
        getInitialFilterValues,
        getMemberPaymentTypes,
        getProgramTypeID,
        getPartners,
        getPeriods,
        getDefaultPeriodID,
        getPaymentTypeID,
        getPartnerID,
        getPeriodID,
        getIsViewButtonDisabled,
        getPopupData,
        getPopupMessage,
        getSubmitText
    ],
    (
        isLoading,
        payments,
        totalPaymentsAmount,
        memberPaymentsCount,
        programPaymentTypes,
        initialFilterValues,
        memberPaymentTypes,
        programTypeID,
        partners,
        periods,
        defaultPeriodID,
        paymentTypeID,
        partnerID,
        periodID,
        isViewButtonDisabled,
        popupData,
        popupMessage,
        popupSubmitText
    ) => {
        return {
            isLoading,
            payments,
            totalPaymentsAmount,
            memberPaymentsCount,
            programPaymentTypes,
            initialFilterValues,
            memberPaymentTypes,
            programTypeID,
            partners,
            periods,
            defaultPeriodID,
            paymentTypeID,
            partnerID,
            periodID,
            isViewButtonDisabled,
            popupData,
            popupMessage,
            popupSubmitText
        };
    }
);