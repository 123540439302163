const PartnerGroupMemberhipsPaymentsResources = {
    labelStartVisitMonth: 'Start Visit Month',
    labelEndVisitMonth: 'End Visit Month',
    labelVisitMonth: 'Visit Month',
    labelPaymentForOneMember: 'Payment For 1 Member',
    labelTotalNumberOfMemberships: 'Total Number of Memberships',
    labelNumberOfActivatedMemberships: 'Number of Activated Memberships',
    labelTotalPayment: 'Total Payment',
    titlePartnerGroupMemberhipsPayments: 'Monthly Payments',
    errorMessagePeriodRange: 'End visit month should be greater than start visit month.',
    buttonShowPayments: 'Run Report',
    labelMonthlyInstallment: 'Monthly Installment',
    labelProgramFee: 'Program Fee',
    labelAdminFee: 'Admin Fee',
    labelRenewalFee: 'Renewal Fee',
    labelFacilityAccessFee: 'Facility Access Fee',
    labelOneTimeFee: 'One-Time Fee',
    labelOneTimeFeeReason: 'One-Time Fee Reason',
    labelStartupFee: 'Startup Fee',
    labelRegisteredMembers: 'Registered Members',
    labelTotalMonthlyDuesAmount: 'Total Monthly Dues Amount',
    labelReimbursedMembers: 'Reimbursed Members',
    labelTotalMonthlyReimbursementAmount: 'Total Monthly Reimbursement Amount',
    periodFormName: 'partnerGroupMembershipsPeriodFiltersForm',
    periodIDFromFieldName: 'periodIDFrom',
    periodIDToFieldName: 'periodIDTo'
};
export default PartnerGroupMemberhipsPaymentsResources;
