import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import BankingInfoResources from '../BankingInfo/bankingInfoResources';
import Button from '../../../components/controls/Button';

function BankAccountInfoRetriver({ payeeToken, retrievePartnerBankAccountDetails, accountNumber, routingNumber }) {
    return <div className="grid__row">
        <div className={
            classNames([
                { 'col-md-9': payeeToken },
                { 'col-md-12': !payeeToken }
            ])
        }
        >
            <Field
                label={BankingInfoResources.payeeTokenLabel}
                name={`bankAccount.${BankingInfoResources.fieldPayeeToken}`}
                component={MuiInputText}
                readOnly
                masked
            />
            {
                routingNumber && accountNumber ?
                    (
                        <div className="partner-billing-info__banking-account-details">
                            <div className="partner-billing-info__banking-account-details-row">
                                <span className="partner-billing-info__banking-account-details-label">{BankingInfoResources.routingNumberLabel}:</span>
                                <span className="partner-billing-info__banking-account-details-value">{routingNumber}</span>
                            </div>
                            <div className="partner-billing-info__banking-account-details-row">
                                <span className="partner-billing-info__banking-account-details-label">{BankingInfoResources.accountNumberLabel}:</span>
                                <span className="partner-billing-info__banking-account-details-value">{accountNumber}</span>
                            </div>
                        </div>
                    )
                    : undefined
            }
        </div>
        {
            payeeToken ?
                (
                    <div className="col-md-3">
                        <Button
                            theme="new btn_new--white btn_new--small"
                            className="partner-billing-info__retrieve-button"
                            type="button"
                            onClick={retrievePartnerBankAccountDetails}
                            disabled={routingNumber && accountNumber}
                        >
                            {BankingInfoResources.buttonRetrieve}
                        </Button>
                    </div>
                )
                : undefined
        }
    </div>;
}

BankAccountInfoRetriver.propTypes = {
    payeeToken: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    retrievePartnerBankAccountDetails: PropTypes.func.isRequired
};

export default BankAccountInfoRetriver;