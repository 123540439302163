import bankAccountTypes from '../../../enums/bankAccountTypes';

const BankingInfoResources = {
    routingNumberLabel: 'Routing Number',
    accountNumberLabel: 'Account Number',
    accountTypeLabel: 'Account Type',
    payeeTokenLabel: 'Payee Token',
    retrieveButtonLabel: 'Retrieve',
    linkButtonNext: 'Next',
    linkButtonPrevious: 'Back',
    warningBoxDescription: 'Your privacy, and compliance to HIPAA & PCI requirements are of the utmost importance. It is with industry leading technology safeguards we protect your information from the moment its types in and on through our Web and processing systems. We feel these extensive privacy features gives everyone peace of mind.',
    descriptionForWithdrawalBankingInfo: 'Please provide your payment information to pay for your monthly membership dues',
    descriptionForDepositinfoBankingInfo: 'Please provide your deposit bank account to receive reimbursements',
    labelSavings: 'Savings',
    labelChecking: 'Checking',
    labelCurrentLinkedAccount: 'Current linked account',
    fieldRoutingNumber: 'routingNumber',
    fieldAccountNumber: 'accountNumber',
    fieldPayeeToken: 'payeeToken',
    fieldBankAccountType: 'bankAccountType',
    buttonRetrieve: 'Retrieve',
    warningBoxTitle: 'Please, know',
    checkboxLabel: 'Use the same bank account as for withdrawals',
    fieldCheckbox: 'IsSameAsWithdrawal'
};
export const BankAccountTypeOptions = Object.freeze([
    {
        id: 'account_type_savings',
        label: BankingInfoResources.labelSavings,
        value: bankAccountTypes.Savings.toString()
    },
    {
        id: 'account_type_checking',
        label: BankingInfoResources.labelChecking,
        value: bankAccountTypes.Checking.toString()
    }
]);
export default BankingInfoResources;