import { createSelector } from 'reselect';
import * as types from './partnerGroupMemberhipsPaymentsTypes';
import { formValueSelector } from 'redux-form';
import partnerGroupMemberhipsPaymentsResources from './partnerGroupMemberhipsPaymentsResources';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === types.GET_PAYMENTS || i === types.GET_USAGE_PERIODS || types.DOWNLOAD_PAYMENTS
            || i === types.GET_PROGRAM_PAYMENT_TYPE_ID
    ).size;

    return size > 0;
};
const getPaymentsFilter = state => state.partnerGroupMemberhipsPaymentsReducer.get('paymentsFilter');
const getPayments = state => state.partnerGroupMemberhipsPaymentsReducer.get('payments');
const getUsagePeriods = state => state.partnerGroupMemberhipsPaymentsReducer.get('usagePeriods');
const getProgramPaymentTypeID = state => state.partnerGroupMemberhipsPaymentsReducer.get('programPaymentTypeID');
const getDefaultPeriodID = createSelector([getUsagePeriods], (periods) => {
    if (periods.size === 0) return undefined;
    return periods.first().get('id');
});
const getForm = formValueSelector(partnerGroupMemberhipsPaymentsResources.periodFormName, state => state.form.toJS());

const getSelectedPeriodIDFrom = (state) => {
    return getForm(state, partnerGroupMemberhipsPaymentsResources.periodIDFromFieldName);
};

const getSelectedPeriodIDTo = (state) => {
    return getForm(state, partnerGroupMemberhipsPaymentsResources.periodIDToFieldName);
};

export default createSelector([getIsLoading, getPaymentsFilter, getPayments, getUsagePeriods, getProgramPaymentTypeID, getDefaultPeriodID, getSelectedPeriodIDFrom, getSelectedPeriodIDTo],
    (isLoading, paymentsFilter, payments, usagePeriods, programPaymentTypeID, defaultPeriodID, selectedPeriodIDFrom, selectedPeriodIDTo) => ({
        isLoading, paymentsFilter, payments, usagePeriods, programPaymentTypeID, defaultPeriodID, selectedPeriodIDFrom, selectedPeriodIDTo
    }));