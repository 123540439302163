import { createSelector } from 'reselect';
import { GET_CLOSED_USAGE_PERIODS } from '../../Shared/Periods/periodsTypes';
import moment from 'moment';
import { MONTH_YEAR } from '../../../constants/formats';
import baseSelectors from '../wellnessProgramPaymentsSelectors';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_CLOSED_USAGE_PERIODS
    ).size;

    return size > 0;
};

const getClosedUsagePeriods = state => state.periodsReducer.get('closedUsagePeriods');
const getDefaultPeriod = createSelector([getClosedUsagePeriods], (periods) => {
    return periods.find(x => x.get('yearMonth') === moment().add(1, 'M')
        .format(MONTH_YEAR)) || periods.first();
});
const getProgramPaymentTypes = state => state.programPaymentTypeReducer.get('programPaymentTypes');
const getDefaultProgramPaymentType = createSelector([getProgramPaymentTypes], programPaymentTypes => programPaymentTypes.find(x => x.get('id') === programPaymentTypesEnum.partnerPaid));

export default createSelector(
    [getIsLoading, baseSelectors, getClosedUsagePeriods, getProgramPaymentTypes, getDefaultPeriod, getDefaultProgramPaymentType],
    (isLoading, baseProps, periods, programPaymentTypes, defaultPeriod, defaultProgramPaymentType) => {
        return {
            isLoading,
            programTypes: baseProps.programTypes,
            defaultProgramType: baseProps.defaultProgramType,
            defaultProgramPaymentType: baseProps.defaultProgramPaymentType,
            periods,
            defaultPeriod,
            programPaymentTypes
        };
    }
);