import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import NewTable from '../../../../components/NewInfoBox/NewTable';
import BulletCell from '../../../../components/NewInfoBox/BulletCell';
import styles from '../reimbursement.module.scss';
import reimbursementResources from '../reimbursementResources';
import getIsLoading from './reimbursementGroupSelectors';
import { PAGE_SIZE } from '../../../../constants/table';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner/Spinner';
import PeriodsFilterForm from '../../../Shared/Periods/PeriodsFilterForm';

function ReimbursementsGroup({ getPlanReimbursementsGroup,
    periods,
    reimbursementsGroup,
    index,
    isLoading
}) {
    const reimbursementItems = reimbursementsGroup.getIn(['reimbursements', 'items']);
    const reimbursementsGroupJS = reimbursementsGroup.toJS();
    const { reimbursements, name, hideReimbursementHistory } = reimbursementsGroupJS;
    const columns = useMemo(
        () => [
            {
                id: 'usagePeriodStartDate',
                Header: reimbursementResources.labelYearMonth,
                accessor: reimbursementResources.accessorYearMonth,
                minWidth: 128,
                width: 128
            },
            {
                Header: reimbursementResources.labelVisitCount,
                accessor: reimbursementResources.accessorVisitCount,
                minWidth: 128,
                width: 128
            },
            {
                Header: reimbursementResources.labelReimbursementAmount,
                accessor: reimbursementResources.accessorReimbursementAmount,
                width: 128,
                maxWidth: 128,
            },
            {
                Header: reimbursementResources.labelHasBeenReimbursed,
                accessor: reimbursementResources.accessorHasBeenReimbursed,
                Cell: cell => BulletCell({ ...cell, value: cell.value ? 'Reimbursed' : 'Failed' }),
                getProps: row => ({
                    theme: row.original.hasBeenReimbursed ? 'green' : 'beet'
                }),
                width: 190,
                minWidth: 190,
            },
            {
                Header: reimbursementResources.labelDescription,
                accessor: reimbursementResources.accessorDescription,
                minWidth: 300,
                width: 300
            },
        ],
        [reimbursements]
    );
    const yearMonthAccessor = (item) => {
        return item.yearMonth;
    };
    const updateHandler = (pageNumber, sortColumn, sortOrder) => {
        if (reimbursementsGroupJS) {
            getPlanReimbursementsGroup(reimbursementsGroupJS, index, pageNumber, PAGE_SIZE, sortColumn, sortOrder);
        }
    };
    const handleSubmit = (items) => {
        reimbursementsGroupJS.usagePeriodIDFrom = items.get('periodIDFrom');
        reimbursementsGroupJS.usagePeriodIDTo = items.get('periodIDTo');
        getPlanReimbursementsGroup(reimbursementsGroupJS, index, 1, PAGE_SIZE);
    };
    return (
        <div>
            {name && <h3 className={styles.reimbursement__title}>{name}</h3>}
            {hideReimbursementHistory
                ? <div className="text-center">{reimbursementResources.noReimbursementDetailsText}</div>
                : <div className={styles.reimbursement__group_container}>
                    {isLoading && <Spinner />}
                    <PeriodsFilterForm
                        form={`planReimbursementsGroup_${index}`}
                        key={index}
                        periods={periods}
                        initialValues={{ periodIDFrom: reimbursementsGroupJS.usagePeriodIDFrom, periodIDTo: reimbursementsGroupJS.usagePeriodIDTo }}
                        onSubmit={handleSubmit}
                    />
                    <div className={styles.reimbursement__table_container}>
                        <NewTable
                            columns={columns}
                            update={updateHandler}
                            initialData={reimbursementItems}
                            getItemId={yearMonthAccessor}
                            initialSortBy={{
                                id: reimbursementResources.defaultSortColumn,
                                desc: true
                            }}
                            tableIndex={index}
                            totalCount={reimbursements.totalCount}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

ReimbursementsGroup.propTypes = {
    index: PropTypes.number,
    getPlanReimbursementsGroup: PropTypes.func,
    reimbursementsGroup: PropTypes.instanceOf(Map),
    periods: PropTypes.instanceOf(List),
    isLoading: PropTypes.bool,
    loadings: PropTypes.instanceOf(List)
};

const mapStateToProps = (state, props) => {
    const { index } = props;
    return {
        isLoading: getIsLoading(state, index),
    };
};

export default connect(mapStateToProps, null)(ReimbursementsGroup);
