import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import validate from './validate';
import resources from '../../Shared/resources';
import periodResources from './periodResources';
import styles from './_period.module.scss';
import FormGroup from '../../../components/controls/FormGroup';
import MuiInputSelect from '../../../components/controls/MuiInputSelect';
import MuiButton from '../../../components/controls/MuiButton';
import { defaultFormProperties } from '../../../utilities/form';

function PeriodsFilterForm({ handleSubmit, periods, error, submitting, periodFromIDLabel, periodToIDLabel, setPeriodFrom, setPeriodTo }) {
    return <div className={styles.period__container}>
        <FormGroup onSubmit={handleSubmit}>
            <div>
                <div className="grid__row">
                    <div className={`col-xs-12 ${styles.period__field}`}>
                        <Field
                            id="start_period"
                            labelId="start_period_label"
                            component={MuiInputSelect}
                            label={periodFromIDLabel}
                            options={periods}
                            name="periodIDFrom"
                            placeholder={periodResources.placeholder}
                            getOptionLabel={option => option.get('yearMonth')}
                            getOptionValue={option => option.get('id')}
                            onChange={setPeriodFrom}
                            hideValidationMessage
                        />
                    </div>
                    <div className={`col-xs-12 ${styles.period__field}`}>
                        <Field
                            id="end_period"
                            labelId="end_period_label"
                            component={MuiInputSelect}
                            label={periodToIDLabel}
                            options={periods}
                            name="periodIDTo"
                            placeholder={periodResources.placeholder}
                            getOptionLabel={option => option.get('yearMonth')}
                            getOptionValue={option => option.get('id')}
                            onChange={setPeriodTo}
                            hideValidationMessage
                        />
                    </div>
                    <div className={`col-xs-12 col-md-auto ${styles.period__button}`}>
                        <MuiButton
                            className="btn_new btn_new--green"
                            type="submit"
                            disabled={submitting}
                        >
                            {resources.applyButtonText}
                        </MuiButton>
                    </div>
                </div>
                {error && <div className="validation-summary validation-summary--no-margin">{error}</div>}
            </div>
        </FormGroup >
    </div>;
};

PeriodsFilterForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitFailed: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    periods: PropTypes.instanceOf(List),
    error: PropTypes.string,
    periodFromIDLabel: PropTypes.string,
    periodToIDLabel: PropTypes.string,
    setPeriodFrom: PropTypes.func,
    setPeriodTo: PropTypes.func
};

PeriodsFilterForm.defaultProps = {
    periodFromIDLabel: periodResources.periodFromIDLabel,
    periodToIDLabel: periodResources.periodToIDLabel,
    setPeriodFrom: () => {},
    setPeriodTo: () => {}
};

export default reduxForm({
    validate,
    ...defaultFormProperties
})(PeriodsFilterForm);
