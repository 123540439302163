import React, { useEffect } from 'react';
import { HEALTHY_CONTRIBUTIONS_LEGAL_NAME } from '../../../../constants/companyNames';
import { HEALTHY_CONTRIBUTIONS_PRIVACY } from '../../../../constants/emails';
import '../../../../styles/_info-page.scss';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';

function AccessibilityAndMaintenance() {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle('Accessibility and Maintenance');
    }, []);
    return <div className="info-page">
        <h1 className="info-page__title">Accessibility and Maintenance</h1>
        <div className="info-page__subtitle">
            {HEALTHY_CONTRIBUTIONS_LEGAL_NAME} (“we”) or (“us”) may modify, change, suspend, terminate or discontinue the
            operation of our sites and apps or the provision of any services without notice and we reserve the right
            to refuse service to anyone at any time, with or without cause.
        </div>
        <div className="info-page__content">
            <div className="info-page__primary-content">
                <section className="info-page__primary-content-section">
                    <h2 className="info-page__primary-content-section-title">Our commitment to accessibility</h2>
                    <div className="info-page__primary-content-section-paragraph">We’re committed to accessibility, diversity and inclusion. Whether you’re accessing this website using
                        assistive technologies like a screen reader, a magnifier, voice recognition software, or switch technology,
                        our mission is to provide the highest level of service that we can.</div>
                </section>
                <section className="info-page__primary-content-section">
                    <h2 className="info-page__primary-content-section-title">Steps we’ve taken and are taking to improve accessibility</h2>
                    <div className="info-page__primary-content-section-paragraph">Here are some of the items we are addressing to make sure our sites and apps are easy to use for all guests:</div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Site Structure.</span> We are working to use headings, lists, paragraphs and other formatting features on the site so that anyone can easily use the site with assistive technology.</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Text Equivalents.</span> People who either can’t see images or hear audio may need some form of a text equivalent to interact with our sites. We are working to ensure all of our images and multimedia are fully accessible through alternative text, captions and transcripts.</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Full Keyboard Access.</span> We are working to ensure that our sites and apps can be fully accessed using a keyboard.</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Site Consistency.</span> We’re generally consistent in the way we build our site and apps so that once you understand how our menus and pages work, our sites function in generally the same way.</li>
                    </ul>
                    <div className="info-page__primary-content-section-paragraph">Please be aware that our efforts are ongoing. To that end, we are in the process of implementing functional
                        improvements consistent with relevant portions of the World Wide Web Consortium’s Web Content Accessibility
                        Guidelines 2.1 Level AA (“WCAG 2.1 AA”). You can find additional information about WCAG 2.1 AA here:&nbsp;
                        <a href="https://www.w3.org/TR/WCAG21/#contents" target="_blank"
                            rel="noopener noreferrer"
                            className="link link--blue"
                        >Web Content Accessibility Guidelines (WCAG) 2.1 (w3.org)
                        </a>.
                    </div>
                </section>
                <section className="info-page__primary-content-section">
                    <h2 className="info-page__primary-content-section-title">Need help or have feedback?</h2>
                    <div className="info-page__primary-content-section-paragraph">If you experience any issues or would like to contact us with suggestions for improvement, please email us at&nbsp;
                        <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a>.
                    </div>
                </section>
            </div>
        </div>
    </div>;
}

export default AccessibilityAndMaintenance;