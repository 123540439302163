import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import selectors from './billingInfoSelectors';
import { getBillingInfo } from './billingInfoActions';
import Spinner from '../../../components/Spinner/Spinner';
import '../../Shared/BankingInfo/_banking-info.scss';
import BankAccountForm from './BankAccountForm';
import IconCaption from '../../../components/IconCaption/IconCaption';
import bankOperationTypes from '../../../enums/bankOperationTypes';
import { change } from 'redux-form';
import billingInfoResources from './billingInfoResources';
import BankingInfoResources from '../../Shared/BankingInfo/bankingInfoResources';
import classNames from 'classnames';
import styles from './_billing_info.module.scss';

function BillingInfoTab({
    isLoading,
    billingInfo,
    getBillingInfo,
    selectedMemberGroup,
    change
}) {
    useEffect(() => {
        if (selectedMemberGroup) {
            getBillingInfo(selectedMemberGroup.membersIds);
        }
    }, [selectedMemberGroup]);
    const setWithdrawalBillingInfo = (form, withdrawalBillingInfo) => {
        if (withdrawalBillingInfo) {
            change(form, billingInfoResources.bankAccountModelName, withdrawalBillingInfo);
        }
    };
    return (
        <div className={classNames([styles.billing_info, 'grid__row'])}>
            {isLoading ?
                <div>
                    <Spinner />
                </div>
                : <></>
            }
            {
                billingInfo.map(
                    (billingInfo, index) => {
                        const data = billingInfo.toJS();
                        const withdrawalFromBankAccount = data.withdrawalFromBankAccount;
                        const depositToBankAccount = data.depositToBankAccount;
                        const memberGuid = data.memberGuid;
                        const depositFormName = `${billingInfoResources.depositFormName}_${index}`;
                        return (
                            <Fragment key={index}>
                                <div className="col-md-8">
                                    <h3 className={styles.billing_info__title}>{billingInfo.get('facilityName')}</h3>
                                    <div className={styles.billing_info__subtitle}>
                                        <IconCaption icon="place" className={styles.billing_info__box_icon} variant={'filled'}
                                            children={billingInfo.get('facilityAddress')}
                                        />
                                    </div>
                                    <div className={styles.billing_info__bank_accounts_container}>
                                        {data.isWithdrawalFromBankAccountRequired &&
                                            <BankAccountForm
                                                form={`${billingInfoResources.withdrawalFormName}_${index}`}
                                                initialValues={{ memberGuid, bankAccount: withdrawalFromBankAccount }}
                                                operationTypeID={bankOperationTypes.Debit}
                                                description={BankingInfoResources.descriptionForWithdrawalBankingInfo}
                                            />}
                                        {data.isDepositToBankAccountRequired &&
                                            <BankAccountForm
                                                form={depositFormName}
                                                initialValues={{ memberGuid, bankAccount: depositToBankAccount }}
                                                operationTypeID={bankOperationTypes.Credit}
                                                description={BankingInfoResources.descriptionForDepositinfoBankingInfo}
                                                setBankAccountInfo={data.isWithdrawalFromBankAccountRequired ? () => setWithdrawalBillingInfo(depositFormName, withdrawalFromBankAccount) : null}
                                            />}
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }
                )
            }
        </div>
    );
}

BillingInfoTab.propTypes = {
    selectedMemberGroup: PropTypes.shape({ fullName: PropTypes.string }),
    getBillingInfo: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    billingInfo: PropTypes.instanceOf(List),
    change: PropTypes.func
};
const mapDispatchToProps = {
    getBillingInfo,
    change
};

export default connect(selectors, mapDispatchToProps)(BillingInfoTab);