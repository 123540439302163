import parseBigInt from './parseBigInt';
import { format } from 'date-fns';
import { SLASHED_US_DATE_FORMAT } from '../constants/formats';

const validationRules = () => {
    const required = (label = '', customMessage) => (value) => {
        if ((typeof (value) === 'string' && /^\s*$/.test(value)) || !value) {
            return customMessage ? customMessage : `${label} is required.`;
        }
        return undefined;
    };

    const requiredIfGreaterThan = (nameField, label, valueToCompare) => (value, allValues) => {
        return allValues.get(nameField) > valueToCompare ? required(label)(value) : undefined;
    };

    const requiredIf = (nameField, label, valueToCompare) => (value, allValues) => {
        return allValues.get(nameField) === valueToCompare ? required(label)(value) : undefined;
    };

    const requiredIfAny = (nameField, label, valuesToCompare) => (value, allValues) => {
        return valuesToCompare.includes(allValues.get(nameField)) ? required(label)(value) : undefined;
    };

    const fileRequired = label => (value) => {
        return required(label, 'Please select a file.')(value);
    };

    const regex = (regex, message) => (value) => {
        if (!value || new RegExp(regex, 'g').test(value)) {
            return undefined;
        }
        return message;
    };

    const passwordRegex = regex(
        '^(?=.*\\d)(?=.*[a-zA-Z])[a-zA-Z\\d!""#$%&\'()*+,-.\\/:;<=>?@\\[\\\\\\]^_`{|}~]{8,}$',
        'A password must be at least 8 characters and contain at least 1 letter and 1 number: letters A-Z (lowercase and uppercase), digits 0-9.'
    );

    const numericRegex = /[^0-9.-]+/g;

    const length = (label, min, max) => (value) => {
        const valueLength = value ? value.length : 0;

        if (min && max && (valueLength < min || valueLength > max)) {
            return min === max
                ? `${label} must be ${min} symbols.`
                : `${label} must be between ${min} and ${max}`;
        } else if (min && valueLength < min) {
            return `${label} must be at least ${min} symbols.`;
        } else if (max && valueLength > max) {
            return `${label} must be at most ${max} symbols.`;
        }

        return undefined;
    };

    const numericOrEmpty = (label = '') => (value) => {
        return isNaN(value) || (typeof (value) === 'string' && value.indexOf(' ') >= 0)
            ? `${label} must be numeric.`
            : undefined;
    };

    const numericOrEmptyWithWildcard = (label, wildcardAllowed) => (
        value,
        allValues,
        formProps
    ) => {
        value = value.replace(new RegExp(wildcardAllowed, 'gi'), '');
        return numericOrEmpty(label)(value);
    };

    const phoneNumber = (label) => {
        return regex(
            '^\\+1\\d{10}$',
            `${label} is invalid. ${label} must be +1XXXXXXXXXX.`,
            true
        );
    };

    const email = (label) => {
        return regex(
            '^([a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+)$',
            `${label} is not valid.`,
            true
        );
    };

    const alphanumeric = (label) => {
        return regex(
            '^[a-zA-Z0-9-]+$',
            `${label} must be alphanumeric.`
        );
    };

    const decimalWithTwoPlaces = (label) => {
        return regex(
            '^\\d+(\\.\\d{0,2})?$',
            `${label} can't contain more than two decimal places.`
        );
    };

    const range = (label, min, max) => (value) => {
        if (value !== undefined && isNaN(value)) {
            value = Number(value.replace(numericRegex, ''));
        }
        return value < min || value > max ? `${label} must be between ${min} and ${max}.` : undefined;
    };

    const greaterThanMultiplicationIf = (fieldName1, fieldName2, message, nameField = undefined, valueToCompare = undefined) =>
        (value, allValues, formProps) => {
            if (value !== undefined && isNaN(value)) {
                value = Number(value.replace(numericRegex, ''));
            }
            return (nameField === undefined || valueToCompare === undefined || allValues.get(nameField) === valueToCompare)
                && value < allValues.get(fieldName1) * allValues.get(fieldName2)
                ? message
                : undefined;
        };

    const greaterThanOrEqualSpecificValue = (valueToCompare, labelFieldToTest = '', type = undefined, dateFormat = SLASHED_US_DATE_FORMAT) => (value) => {
        let fieldToTestValue = value !== undefined && isNaN(value) ? Number(value.replace(numericRegex, '')) : value;
        let formattedValueToCompare = valueToCompare;
        if (fieldToTestValue && type === 'dateString') {
            fieldToTestValue = new Date(fieldToTestValue);
            formattedValueToCompare = format(formattedValueToCompare, dateFormat);
        }
        return fieldToTestValue >= valueToCompare ? undefined : `${labelFieldToTest} can't be less than ${formattedValueToCompare}.`;
    };

    const greaterThanOrEqual = (nameFieldToCompare, labelFieldToTest, labelFieldToCompare, type = undefined) => (value, allValues) => {
        let fieldToTestValue = value !== undefined && type !== 'dateString' && isNaN(value) ? Number(value.replace(numericRegex, '')) : value;
        let fieldToCompareValue = allValues.get(nameFieldToCompare);
        if (fieldToTestValue && fieldToCompareValue && type === 'dateString') {
            fieldToTestValue = new Date(fieldToTestValue);
            fieldToCompareValue = new Date(fieldToCompareValue);
        }
        return !fieldToCompareValue || fieldToTestValue >= fieldToCompareValue ? undefined : `${labelFieldToTest} can't be less than ${labelFieldToCompare}.`;
    };

    const lessThanOrEqual = (nameFieldToCompare, labelFieldToTest, labelFieldToCompare, type = undefined) => (value, allValues) => {
        let fieldToTestValue = value !== undefined && type !== 'dateString' && isNaN(value) ? Number(value.replace(numericRegex, '')) : value;
        let fieldToCompareValue = allValues.get(nameFieldToCompare);
        if (fieldToTestValue && fieldToCompareValue && type === 'dateString') {
            fieldToTestValue = new Date(fieldToTestValue);
            fieldToCompareValue = new Date(fieldToCompareValue);
        }
        return !fieldToCompareValue || fieldToTestValue <= fieldToCompareValue ? undefined : `${labelFieldToTest} can't be greater than ${labelFieldToCompare}.`;
    };

    const integer = label => (value) => {
        const parsed = parseInt(value);
        return parsed.toString() === value.toString() ? undefined : `${label} is not valid.`;
    };

    const bigInt = (base, label) => (value) => {
        return parseBigInt(value, base) === null ? `${label} is not valid.` : undefined;
    };

    const usBankCode = () => {
        return regex(
            '^[a-zA-Z/\\d]{2}$',
            'US Bank Code must consist of 2 latin letters or digits.'
        );
    };

    const notEqualTo = (nameFieldToCompare, customMessage) => (value, allValues) => {
        return value === allValues.get(nameFieldToCompare) ? customMessage : undefined;
    };

    const notExist = (label = '', customMessage, itemsSet = new Set()) => (value) => {
        if (value && itemsSet.has(value.trim().toLowerCase())) {
            return customMessage ? customMessage : `${label} must be unique. Item with ${value} already exist.`;
        }
        return undefined;
    };
    return {
        required,
        requiredIfGreaterThan,
        requiredIf,
        requiredIfAny,
        fileRequired,
        regex,
        passwordRegex,
        length,
        numericOrEmpty,
        numericOrEmptyWithWildcard,
        phoneNumber,
        email,
        range,
        greaterThanMultiplicationIf,
        lessThanOrEqual,
        greaterThanOrEqual,
        greaterThanOrEqualSpecificValue,
        integer,
        bigInt,
        usBankCode,
        alphanumeric,
        decimalWithTwoPlaces,
        notEqualTo,
        notExist
    };
};

export default validationRules();
