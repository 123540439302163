import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { facilityLocationTypeNamesMap, facilityLocationTypeColorsMap } from '../../../../../../enums/facilityLocationTypes';
import BulletCaption from '../../../../../../components/BulletCaption/BulletCaption';
import SearchBox from '../../../../../../components/SearchBox/SearchBox';
import FacilityDetailedInfoPanel from '../FacilityDetailedInfoPanel/FacilityDetailedInfoPanel';
import { toggleSelectedFacility, toggleListView } from '../facilityLocatorMapActions';
import IconButton from '../../../../../../components/IconButton/IconButton';
import MaterialIcon from '../../../../../../components/MaterialIcon/MaterialIcon';
import classNames from 'classnames';
import FacilityLocatorMapLegend from '../FacilityLocatorMapLegend/FacilityLocatorMapLegend';
import facilityLocatorResources from '../../facilityLocatorResources';
import ToolTip from '../../../../../../components/controls/ToolTip';
import { GEOMETRY } from '../../../../../../constants/googleMapsAutocompleteFields';
import { USA } from '../../../../../../constants/abbreviations';
import SharedResources from '../../../../../Shared/resources';
import PlansFilter from '../../../../../../components/PlansFilter/PlansFilter';

function FacilitiesInMapPanel({
    facilities,
    map,
    mapApi,
    selectedFacility,
    history,
    toggleSelectedFacility,
    isEnrollButtonVisible,
    isListView,
    toggleListView,
    showFilter
}) {
    const [isActive, setIsActive] = useState(false);
    const searchInputRef = useRef(null);
    const controlClass = 'active no-click';
    const inputTypeClass = 'btn_new btn_new--white btn-group-primary';
    const defaultToggleBtnClassName = 'facility-locator__facilities-panel-wrapper_toggle-btn';
    const defaultIconBtnClassName = 'facility-locator__chevron_icon-button';
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [hasFilterSelection, setHasFilterSelection] = useState(false);
    const searchInputId = 'mapPanelSearchInputBoxId';
    const listBoxId = 'searchListBoxId';
    const plansFilterId = 'plansFilterId';

    useEffect(() => {
        const googleAutoCompleteOptions = {
            fields: [GEOMETRY],
            componentRestrictions: { country: [USA] },
        };

        const autoComplete = new mapApi.places.Autocomplete(
            searchInputRef.current,
            googleAutoCompleteOptions,
        );

        const setPlace = (place) => {
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }
        };

        const autocompleteService = new mapApi.places.AutocompleteService();
        const placeService = new mapApi.places.PlacesService(map);

        const onPlaceChanged = () => {
            const place = autoComplete.getPlace();

            if (!place.geometry) {
                const predictionList = document.getElementsByClassName('pac-item');
                if(predictionList && predictionList.length > 0) {
                    const firstElement = predictionList[0].textContent;
                    autocompleteService.getPlacePredictions({ input: firstElement, componentRestrictions: { country: [USA] } }, (predictions) => {
                        if (predictions && predictions.length > 0) {
                            placeService.getDetails({ placeId: predictions[0].place_id }, (placeFound, status) => {
                                if (status === 'OK') {
                                    searchInputRef.current.value = predictions[0].description;
                                    setPlace(placeFound);
                                }
                            });
                        }
                    });
                }
            }
            else {
                setPlace(place);
            }

            searchInputRef.current.blur();
        };
        autoComplete.addListener('place_changed', onPlaceChanged);
        autoComplete.bindTo('bounds', map);
    }, [searchInputRef]);

    const handleShowFilterPanel = () => {
        setShowFilterPanel(!showFilterPanel);
    };

    const handleFilterChange = (items) => {
        setShowFilterPanel(false);
        setHasFilterSelection(items && items.length > 0 && items.some(plan => plan.checked
            || (plan.programNames && plan.programNames.some(program => program.checked))));
    };

    return facilities && <div className={classNames(['facility-locator__facilities-panel-wrapper', { 'facility-locator__facilities-panel-wrapper--overflow-on-sm-only': isListView }])}>
        <ToolTip title={isActive ? SharedResources.toggleShowToolTip : SharedResources.toggleHideToolTip} arrow
            placement="right"
        >
            <button className={classNames([`${defaultToggleBtnClassName} ${defaultIconBtnClassName}`, { [`${defaultToggleBtnClassName}-reset`]: isActive }])} onClick={() => setIsActive(!isActive)} aria-label={isActive ? 'Expand Side Panel' : 'Collapse Side Pannel'} aria-expanded={isActive ? 'false' : 'true'}>
                <MaterialIcon icon={isActive ? 'chevron_right' : 'chevron_left'} className="facility-locator__chevron-icon" />
            </button>
        </ToolTip>
        <div className={classNames(['facility-locator__facilities-panel',
            { 'facility-locator__facilities-panel-wrapper--hidden': isActive },
            { 'list-view': isListView }])}
        >
            <div className={'facility-locator__search-box-underlay'}>
                {map && mapApi &&
                    <SearchBox map={map} mapApi={mapApi} showFilterIcon={showFilter} hasFilterSelection={hasFilterSelection} onShowFilter={handleShowFilterPanel}
                        placeholder={facilityLocatorResources.placeholderSearch} ref={searchInputRef}
                        filterTooltip={facilityLocatorResources.filterTooltip}
                        searchInputId={searchInputId}
                        listBoxId={listBoxId}
                        ariaHasPopup="listbox"
                        ariaAutocomplete="list"
                        filterExpandContentId={plansFilterId}
                        showFilterPanel={showFilterPanel}
                    />}
                <div className="btn-group d-sm">
                    <button className={classNames([`${inputTypeClass}`, { [`${controlClass}`]: isListView }])} onClick={() => toggleListView(isListView)}>List view</button>
                    <button className={classNames([`${inputTypeClass}`, { [`${controlClass}`]: !isListView }])} onClick={() => toggleListView(isListView)}>Map view</button>
                </div>
                {showFilter && <PlansFilter showFilter={showFilterPanel} showSelected={!selectedFacility} isListView={isListView} onFilterChange={handleFilterChange} searchInputId={searchInputId}
                    listBoxId={listBoxId} plansFilterId={plansFilterId}
                               />
                }
                <div className={classNames([
                    'facility-locator__facilities-panel-subtitle',
                    { 'facility-locator--hidden-on-mobile': !isListView }])}
                >
                    {selectedFacility &&
                        <IconButton icon={'arrow_back'} text={facilityLocatorResources.backButtonText}
                            role="button"
                            tabIndex="0"
                            iconClassName="facility-locator__back-icon"
                            buttonClassName="facility-locator__back-icon-button"
                            clickHandler={toggleSelectedFacility}
                        />
                    } {!selectedFacility &&
                        (facilities.length > 0 ? <h4>Found {facilities.length} items</h4> : <h4 className="facility-locator__facilities-panel-no-clubs">{facilityLocatorResources.noClubsInThisArea}</h4>)}
                </div>
            </div>
            {selectedFacility &&
                <FacilityDetailedInfoPanel facility={selectedFacility} history={history}
                    isEnrollButtonVisible={isEnrollButtonVisible} isListView={isListView}
                />
            }
            {!selectedFacility && <div className={classNames(['facility-locator__facilities-panel-list', { 'facility-locator__facilities-panel-list--hidden-on-mobile': !isListView }])}>
                {facilities.map((facility, i) => {
                    return <Fragment key={i}>
                        <div className="facility-locator__facilities-panel-item" onClick={() => toggleSelectedFacility(facility.id)} role="group" aria-label="Club">
                            <div className="facility-locator__facilities-panel-item-title">
                                <h4 className="facility-locator__facilities-panel-item-name">{facility.displayName}</h4>
                                <div className="facility-locator__facilities-panel-item-distnace">{facility.distanceToCenterForDisplay}</div>
                            </div>
                            <BulletCaption color={facilityLocationTypeColorsMap[facility.facilityLocationType]} text={facilityLocationTypeNamesMap[facility.facilityLocationType]} />
                            <div className="facility-locator__facilities-panel-item-text-line">{`${facility.address1} ${facility.city}, ${facility.state} ${facility.zipCode}`}</div>
                            <div className="facility-locator__facilities-panel-item-text-line">{facility.contactNumber}</div>
                        </div>
                    </Fragment>;
                })}
            </div>}
            {<div className={classNames(['facility-locator__map-legend-wrapper', { 'facility-locator--hidden-on-mobile': isListView }])}>
                <FacilityLocatorMapLegend />
            </div>
            }
        </div>
    </div>;
}

FacilitiesInMapPanel.propTypes = {
    facilities: PropTypes.arrayOf(PropTypes.object),
    selectedFacility: PropTypes.object,
    map: PropTypes.object.isRequired,
    mapApi: PropTypes.object.isRequired,
    toggleSelectedFacility: PropTypes.func.isRequired,
    isEnrollButtonVisible: PropTypes.bool.isRequired,
    toggleListView: PropTypes.func.isRequired,
    isListView: PropTypes.bool,
    showFilter: PropTypes.bool
};

const actions = {
    toggleSelectedFacility,
    toggleListView
};

export default connect(null, actions)(FacilitiesInMapPanel);
