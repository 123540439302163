import { GET_BILLING_INFO, SET_BILLING_INFO, UPDATE_BANK_ACCOUNT  } from './billingInfoTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as BillingInfoService from './billingInfoService';

const setBillingInfo = billingInfo => ({
    type: SET_BILLING_INFO,
    payload: {
        billingInfo
    }
});

const setBankAccount = bankAccount => ({
    type: UPDATE_BANK_ACCOUNT,
    payload: {
        bankAccount
    }
});

export const getBillingInfo = membersGuids => (dispatch) => {
    dispatch(loadingActions.setLoading(GET_BILLING_INFO));
    return BillingInfoService.getBillingInfo(membersGuids)
        .then((billingInfo) => {
            dispatch(setBillingInfo(billingInfo));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_BILLING_INFO));
        });
};

export const updateBankAccount = bankingInfo => (dispatch) => {
    dispatch(loadingActions.setLoading(UPDATE_BANK_ACCOUNT));
    return BillingInfoService.updateBankingInfo(bankingInfo)
        .then((result) => {
            dispatch(setBankAccount(result.data));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(UPDATE_BANK_ACCOUNT));
        });
};

export default {
    getBillingInfo,
    updateBankAccount
};
