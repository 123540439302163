import React from 'react';
import WarningBox from '../../../components/WarningBox/WarningBox';
import BankingInfoResources from '../BankingInfo/bankingInfoResources';

function BankAccountSecurityNote() {
    return (
        <WarningBox title={BankingInfoResources.warningBoxTitle} description={BankingInfoResources.warningBoxDescription} />
    );
}

export default BankAccountSecurityNote;