const legalContractResources = {
    legalCotractTitleEnroll: 'Enroll',
    legalCotractTitleEnrolled: 'Enrolled',
    legalCotractTitleFormat: '{0} in {1}',
    legalContractTitleKeyfobStep: 'Key Fob',
    legalContractTitleDepositDetailsStep: 'Deposit Details',
    legalContractTitleWithdrawalDetailsStep: 'Withdrawal Details',
    legalContractTitleAgreementStep: 'Agreement',
    legalContractTitlePaymentDetailsStep: 'Payment Details',
    formNameLegalContract: 'legalcontract',
    depositToBankAccountModelName: 'depositToBankAccount',
    withdrawalFromBankAccountModelName: 'withdrawalFromBankAccount',
    linkButtonNextText: 'Next',
    linkButtonPrevious: 'Back',
};
export default legalContractResources;