import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import bankingInfoResources from './bankingInfoResources';

const getState = state => state;
const getFormName = (state, formName) => formName;
const formSelector = formName => formValueSelector(formName, state => state.form.toJS());
const getPrefix = (state, formName, prefix) => prefix;
const getIsEmpty = createSelector([getState, getFormName, getPrefix], (state, formName, prefix) => {
    let fieldAccountNumber = bankingInfoResources.fieldAccountNumber;
    let fieldRoutingNumber = bankingInfoResources.fieldRoutingNumber;
    let fieldBankAccountType = bankingInfoResources.fieldBankAccountType;
    if (prefix) {
        fieldAccountNumber = `${prefix}.${fieldAccountNumber}`;
        fieldRoutingNumber = `${prefix}.${fieldRoutingNumber}`;
        fieldBankAccountType = `${prefix}.${fieldBankAccountType}`;
    }
    return !formSelector(formName)(state, fieldRoutingNumber) && !formSelector(formName)(state, fieldAccountNumber) && !formSelector(formName)(state, fieldBankAccountType);
});

export default getIsEmpty;