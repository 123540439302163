import { format } from 'date-fns';
import { getFirstDayOfNextMonth } from '../../../../utilities/date';
import { SLASHED_UNICODE_DATE_FORMAT } from '../../../../constants/formats';

const keyfobsAndEmployeesResources = {
    labelKeyfob: 'Keyfob',
    labelKeyfobAccessPass: 'Access Pass',
    labelKeyfobStatus: 'Status',
    labelFacilityID: 'Facility ID',
    labelFacilityName: 'Facility Name',
    labelProgramEnrollmentDate: 'Program Enrollment Date',
    labelEmployeeName: 'Employee Name',
    labelDateOfBirth: 'Date Of Birth',
    labelEmailAddress: 'Email Address',
    labelEmail: 'Email',
    labelMobilePhone: 'Mobile phone',
    labelLastMonthUsageCount: 'Last month usage',
    labelLastMonthVisitsCount: 'Last month visits',
    labelActions: 'Actions',
    buttonReassign: 'Edit Key Fob',
    buttonDelete: 'Delete',
    buttonDeactivate: 'Deactivate',
    titleKeyfobsList: 'Keyfobs And Employees',
    buttonDownload: 'Download',
    buttonInvite: 'Invite',
};

export function GetDeactivationConfirmationMessage(memberFirstName, memberLastName, facilityMemberID) {
    return `Are you sure you want to deactivate ${memberFirstName} ${memberLastName} with keyfob ${facilityMemberID}?`;
};

export function GetReassignConfirmationMessage(oldFacilityMemberID) {
    return `Are you sure you want to replace keyfob ${oldFacilityMemberID}?`;
};

export function GetKeyfobDeleteConfirmationMessage(facilityMemberID) {
    return `Are you sure you want to delete keyfob ${facilityMemberID}?`;
};

export function GetMemberDeleteConfirmationMessage() {
    return 'Once you click "Delete" the member will be deleted immediately.';
};

export function GetMemberDeleteAtNextMonthConfirmationMessage() {
    return `Once you click "Delete" the member will be deleted as of ${format(getFirstDayOfNextMonth(), SLASHED_UNICODE_DATE_FORMAT)}.`;
};

export function GetMemberDeleteConfirmationTitle(memberFirstName, memberLastName) {
    return `Delete a member "${memberFirstName} ${memberLastName}"?`;
};

export default keyfobsAndEmployeesResources;