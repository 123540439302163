import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Table from '../../../components/InfoBox/Table';
import InputSelect from '../../../components/controls/InputSelect';
import InputCheckbox from '../../../components/controls/InputCheckbox';
import ExcelImage from '../../../components/Image/ExcelImage/ExcelImage';
import partnerResources from '../../Shared/Partners/partnerResources';
import groupMembershipResources from '../../Shared/Programs/groupMembershipResources';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import actions from './partnerPaymentsSectionActions';
import selectors from './partnerPaymentsSectionSelectors';
import UndoAmountCorrectionActionCell from '../UndoAmountCorrectionActionCell';
import EditAmountToBeSentEditableCell from '../EditAmountToBeSentEditableCell';
import { getClosedUsagePeriods, getPeriodsUntilCurrent } from '../../Shared/Periods/periodsActions';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import { partnerBillingTypesNamesMap } from '../../../enums/partnerBillingTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import paymentTypesEnum from '../../../enums/paymentTypes';

function PartnerPaymentsSection({
    isLoading,
    programTypes,
    programPaymentTypes,
    partners,
    periods,
    payments,
    paymentTypes,
    getPayments,
    paymentsFilter,
    setProgramPaymentType,
    setPeriod,
    setPartner,
    setPaymentType,
    defaultPeriod,
    downloadPayments,
    programPaymentTypeId,
    periodId,
    partnerId,
    showCorrectedOnly,
    setShowCorrectedOnly,
    getPartnersListByProgramPaymentType,
    updatePartnerPaymentAmountToBeSent,
    undoPartnerPaymentAmountCorrection,
    getClosedUsagePeriods,
    getPeriodsUntilCurrent,
    programTypeId,
    paymentTypeId,
    defaultPaymentType,
    getPaymentTypesByProgramPaymentType
}) {
    useEffect(() => {
        if (programPaymentTypeId) {
            getPartnersListByProgramPaymentType(programPaymentTypeId);
            getPaymentTypesByProgramPaymentType(programPaymentTypeId);
            if (programPaymentTypeId === programPaymentTypesEnum.memberPaid || (programPaymentTypeId === programPaymentTypesEnum.partnerMemberPaid
                && paymentTypeId === paymentTypesEnum.gmPartnerFees)) {
                getPeriodsUntilCurrent();
            }
            else if (programPaymentTypeId === programPaymentTypesEnum.partnerPaid || (programPaymentTypeId === programPaymentTypesEnum.partnerMemberPaid
                && paymentTypeId === paymentTypesEnum.gmPartnerDues)) {
                getClosedUsagePeriods();
            }
            setPartner(null);
            setPaymentType(defaultPaymentType);
            setPeriod(null);
        }
    }, [programPaymentTypeId]);
    useEffect(() => {
        if (defaultPeriod) {
            setPeriod(defaultPeriod);
        }
    }, [defaultPeriod]);
    useEffect(() => {
        if (programPaymentTypeId && paymentTypeId && periodId) {
            getPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly);
        }
    }, [programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly]);
    const exportToExcel = () => {
        if (programPaymentTypeId && periodId) {
            downloadPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly);
        }
    };
    const updateAmountToBeSentHandler = (rowIndex, columnId, value, rowData) => {
        updatePartnerPaymentAmountToBeSent(programTypeId, programPaymentTypeId, rowData[wellnessProgramPaymentResources.accessorId], value);
    };
    const undoHandler = (paymentId) => {
        undoPartnerPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId)
            .then(() => {
                getPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly);
            });
    };
    const onChangeShowCorrectedOnly = (event) => {
        setShowCorrectedOnly(event.target.checked);
    };
    const { gmrAddProgram } = useFlags();
    const feesColumns = useMemo(
        () => [
            {
                Header: partnerResources.labelPartnerIdNumber,
                accessor: 'partnerIDNumber',
                minWidth: 180
            },
            {
                Header: partnerResources.labelName,
                accessor: 'partnerName',
                minWidth: 300
            },
            {
                Header: gmrAddProgram ? groupMembershipResources.labelVisitMonth : groupMembershipResources.labelUsagePeriod,
                accessor: 'usagePeriod',
                minWidth: 200
            },
            {
                Header: gmrAddProgram ? groupMembershipResources.labelProgramFee : groupMembershipResources.labelMonthlyInstallment,
                accessor: 'monthlyInstallment',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: groupMembershipResources.labelAdminFee,
                accessor: 'adminFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            ...gmrAddProgram ?
                []
                : [{
                    Header: groupMembershipResources.labelStartupFee,
                    accessor: 'startupFee',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                }],
            {
                Header: groupMembershipResources.labelRenewalFee,
                accessor: 'renewalFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                minWidth: 150
            },
            {
                Header: groupMembershipResources.labelFacilityAccessFee,
                accessor: 'facilityAccessFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                minWidth: 150
            },
            {
                Header: groupMembershipResources.labelOneTimeFee,
                accessor: 'oneTimeFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                minWidth: 150
            },
            {
                Header: groupMembershipResources.labelOneTimeFeeReason,
                accessor: 'oneTimeFeeReason',
                minWidth: 180
            },
            {
                Header: groupMembershipResources.labelTotalPayment,
                accessor: 'totalPayment'
            },
            {
                Header: groupMembershipResources.labelPartnerAmountToBeSent,
                accessor: 'amountToBeSent',
                Cell: EditAmountToBeSentEditableCell,
                width: 100
            },
            ...gmrAddProgram ?
                [{
                    Header: groupMembershipResources.labelBillingType,
                    accessor: 'partnerBillingTypeID',
                    minWidth: 250,
                    Cell: (cell) => { return cell.value ? partnerBillingTypesNamesMap[cell.value] : null; },
                },
                {
                    Header: groupMembershipResources.labelBillingInfo,
                    accessor: 'billingInfo',
                }]
                : [{
                    Header: groupMembershipResources.labelPayeeToken,
                    accessor: 'payeeToken',
                    minWidth: 340
                }],
            {
                Header: groupMembershipResources.labelStatus,
                accessor: 'status',
                minWidth: 200
            },
            {
                Header: groupMembershipResources.labelStatusDate,
                accessor: 'paymentStatusDate'
            },
            {
                Header: 'Actions',
                disableSortBy: true,
                getProps: () => ({
                    handleUndo: undoHandler
                }),
                Cell: UndoAmountCorrectionActionCell,
                accessor: row => row,
                minWidth: 200
            }
        ],
        [partnerResources, groupMembershipResources, undoHandler, gmrAddProgram]
    );
    const duesAndReimbursementsColumns = useMemo(
        () => [
            {
                Header: partnerResources.labelPartnerIdNumber,
                accessor: 'partnerIDNumber',
                minWidth: 180
            },
            {
                Header: partnerResources.labelName,
                accessor: 'partnerName',
                minWidth: 300
            },
            {
                Header: groupMembershipResources.labelTotalPayment,
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                accessor: 'totalPayment'
            },
            {
                Header: groupMembershipResources.labelPartnerAmountToBeSent,
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                accessor: 'amountToBeSent',
                width: 100
            },
            {
                Header: groupMembershipResources.labelBillingType,
                accessor: 'partnerBillingTypeID',
                minWidth: 250,
                Cell: (cell) => { return cell.value ? partnerBillingTypesNamesMap[cell.value] : null; },
            },
            {
                Header: groupMembershipResources.labelBillingInfo,
                accessor: 'billingInfo',
            },
            {
                Header: groupMembershipResources.labelStatus,
                accessor: 'status',
                minWidth: 200
            },
            {
                Header: groupMembershipResources.labelStatusDate,
                accessor: 'paymentStatusDate'
            },
            {
                Header: 'Actions',
                disableSortBy: true,
                getProps: () => ({
                    handleUndo: undoHandler
                }),
                Cell: UndoAmountCorrectionActionCell,
                accessor: row => row,
                minWidth: 200
            }
        ],
        [partnerResources, groupMembershipResources, undoHandler]
    );
    const defaultSorted = useMemo(() => [{ id: 'partnerIDNumber' }]);
    return <InfoBox title={wellnessProgramPaymentResources.titlePartnerPayments} bordered>
        {isLoading ?
            <div>
                <Spinner />
            </div>
            : <></>
        }
        <div className="grid__row">
            <div className="col-lg-3">
                {
                    gmrAddProgram ?
                        <InputSelect
                            label={wellnessProgramPaymentResources.labelProgramType}
                            value={paymentsFilter.get('programPaymentType')}
                            options={programPaymentTypes}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            onChange={setProgramPaymentType}
                        />
                        :
                        <InputSelect
                            label={wellnessProgramPaymentResources.labelForProgramType}
                            value={paymentsFilter.get('programType')}
                            options={programTypes}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            isDisabled
                        />
                }
            </div>
        </div>
        {gmrAddProgram ?
            <div className="grid__row">
                <div className="col-lg-3">
                    <InputSelect
                        label={wellnessProgramPaymentResources.labelForPaymentType}
                        value={paymentsFilter.get('paymentType')}
                        options={paymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                        onChange={setPaymentType}
                    />
                </div>
            </div>
            : null
        }
        <div className="grid__row">
            <div className="col-lg-3">
                <InputSelect
                    label={gmrAddProgram ? wellnessProgramPaymentResources.labelPartner : wellnessProgramPaymentResources.labelForPartner}
                    value={paymentsFilter.get('partner')}
                    options={partners}
                    getOptionLabel={option => option.get('name')}
                    getOptionValue={option => option.get('id')}
                    onChange={setPartner}
                />
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg-3">
                <InputSelect
                    label={gmrAddProgram ? wellnessProgramPaymentResources.labelVisitMonth : wellnessProgramPaymentResources.labelForUsagePeriod}
                    value={paymentsFilter.get('period')}
                    options={periods}
                    getOptionLabel={option => option.get('yearMonth')}
                    getOptionValue={option => option.get('id')}
                    onChange={setPeriod}
                />
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg-3">
                <InputCheckbox
                    id="show-corrected-payments-only"
                    label={wellnessProgramPaymentResources.labelShowAllCorrectedPayments}
                    checked={paymentsFilter.get('showCorrectedOnly')}
                    onChange={onChangeShowCorrectedOnly}
                />
            </div>
            <div className="col-lg-9">
                <ExcelImage
                    onClick={exportToExcel}
                />
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg">
                <Table
                    columns={paymentTypeId !== paymentTypesEnum.gmPartnerDues && paymentTypeId !== paymentTypesEnum.gmMemberReimbursements ? feesColumns : duesAndReimbursementsColumns}
                    data={payments.toJS()}
                    defaultSorted={defaultSorted}
                    updateCellHandler={updateAmountToBeSentHandler}
                />
            </div>
        </div>
    </InfoBox>;
}
PartnerPaymentsSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    periods: PropTypes.object,
    payments: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    paymentTypes: PropTypes.object.isRequired,
    paymentsFilter: PropTypes.object.isRequired,
    setProgramPaymentType: PropTypes.func.isRequired,
    setPeriod: PropTypes.func.isRequired,
    setPartner: PropTypes.func.isRequired,
    setPaymentType: PropTypes.func.isRequired,
    getPayments: PropTypes.func.isRequired,
    defaultPeriod: PropTypes.object,
    downloadPayments: PropTypes.func.isRequired,
    programPaymentTypeId: PropTypes.number,
    partnerId: PropTypes.number,
    periodId: PropTypes.number,
    updatePartnerPaymentAmountToBeSent: PropTypes.func.isRequired,
    undoPartnerPaymentAmountCorrection: PropTypes.func.isRequired,
    showCorrectedOnly: PropTypes.bool.isRequired,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired,
    setShowCorrectedOnly: PropTypes.func.isRequired,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    getPeriodsUntilCurrent: PropTypes.func.isRequired,
    programTypeId: PropTypes.number,
    paymentTypeId: PropTypes.number,
    defaultPaymentType: PropTypes.object.isRequired,
    getPaymentTypesByProgramPaymentType: PropTypes.func.isRequired
};
const mapDispatchToProps = {
    getClosedUsagePeriods,
    getPeriodsUntilCurrent,
    ...actions,
};
export default connect(selectors, mapDispatchToProps)(PartnerPaymentsSection);
