import React from 'react';
import PropTypes from 'prop-types';
import Button from '../controls/Button';
import './_call-to-action.scss';

function CallToActionBody({ title, subtitle, description, buttonText, buttonClickHandler, columns }) {
    return (
        <div className={`call-to-action__content-container call-to-action__content-container--columns-${columns}`}>
            <div>
                {subtitle && <h2 className="call-to-action__subtitle">
                    {subtitle}
                </h2>}
                <h1 className={`call-to-action__title call-to-action__title--columns-${columns}`}>
                    {title}
                </h1>
            </div>
            <div className="call-to-action__description-container">
                <div className={`call-to-action__description call-to-action__description--columns-${columns}`}>
                    {description}
                </div>
                {buttonClickHandler && <div className="call-to-action__button-container">
                    <Button className="btn_new btn_new--blue" role="link"
                        onClick={buttonClickHandler}
                    >
                        {buttonText}
                    </Button>
                </div>}
            </div>
        </div>
    );
}

CallToActionBody.defaultProps = {
    columns: 1
};

CallToActionBody.propTypes = {
    subtitle: PropTypes.string,
    title: PropTypes.element.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    buttonText: PropTypes.string,
    buttonClickHandler: PropTypes.func,
    columns: PropTypes.number
};

export default CallToActionBody;
