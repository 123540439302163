const reimbursementResources = {
    defaultSortColumn: 'usagePeriodStartDate',
    accessorYearMonth: 'yearMonth',
    accessorVisitCount: 'visitCount',
    accessorReimbursementAmount: 'reimbursementAmount',
    accessorHasBeenReimbursed: 'hasBeenReimbursed',
    accessorDescription: 'description',
    labelYearMonth: 'Month',
    labelVisitCount: 'Visit',
    labelReimbursementAmount: 'Amount',
    labelHasBeenReimbursed: 'Status',
    labelDescription: 'Reason',
    noReimbursementDetailsText: 'There are no reimbursement details.',
    defaultMonthCount: 6,
};
export default reimbursementResources;