import * as types from './reimbursementTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as ReimbursementService from './reimbursementService';

const setReimbursementsGroupList = (reimbursements) => {
    return { type: types.SET_REIMBURSEMENTS_GROUP_LIST, reimbursements };
};

export const getReimbursementsGroupList = (membersGuids, usagePeriodIDFrom, usagePeriodIDTo) => (dispatch) => {
    dispatch(loadingActions.setLoading(types.GET_REIMBURSEMENTS_GROUP_LIST));
    return ReimbursementService.getReimbursementsGroupList(membersGuids, usagePeriodIDFrom, usagePeriodIDTo)
        .then((reimbursements) => {
            dispatch(setReimbursementsGroupList(reimbursements));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(types.GET_REIMBURSEMENTS_GROUP_LIST));
        });
};

const setReimbursementsGroupAction = (planReimbursementsGroup) => {
    return { type: types.SET_REIMBURSEMENTS_GROUP, planReimbursementsGroup };
};

export const setReimbursementsItemsAction = (planReimbursementsGroup) => {
    return { type: types.SET_REIMBURSEMENTS_ITEMS, planReimbursementsGroup };
};

export const getPlanReimbursementsGroup = (reimbursementsGroup, index, pageNumber, pageSize, sortBy, sortOrder) => (dispatch) => {
    const getReimbursementsGroupType = `${types.GET_REIMBURSEMENTS_GROUP}_${index}`;
    dispatch(loadingActions.setLoading(getReimbursementsGroupType));
    return ReimbursementService.getPlanReimbursementsGroup(reimbursementsGroup, pageNumber, pageSize, sortBy, sortOrder)
        .then((result) => {
            result.usagePeriodIDFrom = reimbursementsGroup.usagePeriodIDFrom;
            result.usagePeriodIDTo = reimbursementsGroup.usagePeriodIDTo;
            if (pageNumber === 1) {
                dispatch(setReimbursementsGroupAction(result));
            }
            else {
                dispatch(setReimbursementsItemsAction(result));
            }
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(getReimbursementsGroupType));
        });
};
