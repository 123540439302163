import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import selectors from './reimbursementSelectors';
import { getReimbursementsGroupList, getPlanReimbursementsGroup } from './reimbursementActions';
import Spinner from '../../../components/Spinner/Spinner';
import ReimbursementsGroup from './ReimbursementsGroup/ReimbursementsGroup';
import { getClosedUsagePeriods } from '../../Shared/Periods/periodsActions';

function Reimbursement({ getReimbursementsGroupList,
    isLoading,
    reimbursementsGroups,
    getPlanReimbursementsGroup,
    selectedMemberGroup,
    defaultPeriodIDFrom,
    defaultPeriodIDTo,
    getClosedUsagePeriods,
    periods
}) {
    useEffect(() => {
        if (selectedMemberGroup && defaultPeriodIDFrom && defaultPeriodIDTo) {
            getReimbursementsGroupList(selectedMemberGroup.membersIds, defaultPeriodIDFrom, defaultPeriodIDTo);
        }
    }, [selectedMemberGroup, defaultPeriodIDFrom, defaultPeriodIDTo]);
    useEffect(() => {
        getClosedUsagePeriods();
    }, []);
    return isLoading
        ? (<Spinner />)
        :
        (<div className="reimbursement" id="reimbursement">
            {reimbursementsGroups && reimbursementsGroups.map((reimbursementsGroup, index) => (
                <ReimbursementsGroup
                    key={index}
                    index={index}
                    periods={periods}
                    reimbursementsGroup={reimbursementsGroup}
                    getPlanReimbursementsGroup={getPlanReimbursementsGroup}
                />
            ))
            }
        </div>);
}

Reimbursement.propTypes = {
    getReimbursementsGroupList: PropTypes.func,
    getPlanReimbursementsGroup: PropTypes.func,
    availableSubmissionPeriods: PropTypes.instanceOf(List),
    selectedMemberGroup: PropTypes.object,
    reimbursementsGroups: PropTypes.instanceOf(List),
    isLoading: PropTypes.bool,
    periods: PropTypes.object,
    defaultPeriodIDFrom: PropTypes.number,
    defaultPeriodIDTo: PropTypes.number,
    getClosedUsagePeriods: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getReimbursementsGroupList,
    getPlanReimbursementsGroup,
    getClosedUsagePeriods
};

export default connect(selectors, mapDispatchToProps)(Reimbursement);