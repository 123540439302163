import React from 'react';
import PropTypes from 'prop-types';
import { change, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import MuiButton from '../../../components/controls/MuiButton';
import FormGroup from '../../../components/controls/FormGroup';
import BankingInfoResources from './bankingInfoResources';
import BankAccount from './BankAccount';
import './_banking-info.scss';
import { connect } from 'react-redux';
import getIsEmpty from './bankingInfoStepFormSelectors';

function BankingInfoStepForm({ handleSubmit,
    submitting,
    isEmpty,
    onPrevStep,
    onNextStep,
    formParams,
    form,
    change }) {
    return (
        <FormGroup onSubmit={handleSubmit(() => {
            change(form, `${formParams.bankAccountModelName}.operationTypeID`, formParams.operationTypeID);
            onNextStep();
        })}
        >
            <BankAccount
                description={formParams.description}
                setBankAccountInfo={formParams.setBankAccountInfo}
                operationTypeID={formParams.operationTypeID}
                bankAccountModelName={formParams.bankAccountModelName}
                buttons={
                    <>
                        <MuiButton className="btn_new btn_new--green"
                            disabled={isEmpty || submitting}
                            type="submit"
                        >
                            {BankingInfoResources.linkButtonNext}
                        </MuiButton>
                        <MuiButton className="btn_new btn_new--white"
                            onClick={onPrevStep}
                        >
                            {BankingInfoResources.linkButtonPrevious}
                        </MuiButton>
                    </>
                }
            />
        </FormGroup >
    );
}

BankingInfoStepForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    isEmpty: PropTypes.bool.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onNextStep: PropTypes.func.isRequired,
    formParams: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
    const { form, formParams } = props;
    return {
        isEmpty: getIsEmpty(state, form, formParams.bankAccountModelName),
    };
};

const mapDispatchToProps = {
    change
};

const connectStateToProps = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
    ...defaultFormProperties,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    keepDirtyOnReinitialize: true
})(connectStateToProps(BankingInfoStepForm));