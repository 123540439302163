import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header/Header';
import Topbar from '../components/Topbar/Topbar';
import Footer from '../components/Footer/Footer';
import Sidebar from '../components/Sidebar/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { routeType } from '../routing/routes';
import classNames from 'classnames';
import './_layout.scss';
import LoginPopup from '../components/Login/LoginPopup';
import ForgotPasswordPopup from '../components/ForgotPassword/ForgotPasswordPopup';
import ForgotPasswordResultPopup from '../components/ForgotPassword/ForgotPasswordResultPopup';

const MainLayout = ({ children, match, history, topbarContent, filteredRoutes, hideSidebar }) => {
    return (
        <div className="container">
            <LoginPopup history={history}/>
            <ForgotPasswordPopup/>
            <ForgotPasswordResultPopup/>
            <Header history={history} />
            <Topbar topbarContent={topbarContent} match={match}
                history={history}
            />
            <div className="layout">
                {
                    hideSidebar
                        ?
                            <></>
                        :
                    <Sidebar match={match}
                        filteredRoutes={filteredRoutes}
                    />
                }
                <a href="#main" className="skip-link">Skip to main content</a>
                <div id="main" role="main" tabIndex="-1" className={classNames([
                    'layout__content',
                    { 'layout__content--with-sidebar': !hideSidebar }])}
                >
                    <ErrorBoundary>
                        <div className="route-wrapper">{children}</div>
                    </ErrorBoundary>
                </div>
            </div>
            <Footer />
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node,
    topbarContent: PropTypes.object,
    filteredRoutes: PropTypes.arrayOf(routeType),
    match: PropTypes.object,
    history: PropTypes.object.isRequired,
    hideSidebar: PropTypes.bool
};

export default MainLayout;
