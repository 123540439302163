import roles from '../enums/roles';
import routes from './routes';
import PartnersPage from '../features/Partners/PartnersPage';
import PartnersList from '../features/Partners/PartnersList/PartnersList';
import AddPartnerPage from '../features/Partners/AddPartner/AddPartnerPage';
import PartnerInfoPage from '../features/PartnerInfo/PartnerInfoPage';
import GeneralInfoTab from '../features/PartnerInfo/GeneralInfo/GeneralInfoTab';
import PartnerBankingInfoTab from '../features/PartnerInfo/BankingInfo/PartnerBankingInfoTab';
import PartnerBillingInfoTab from '../features/PartnerInfo/BillingInfo/PartnerBillingInfoTab';
import DashboardPartner from '../features/Dashboard/DashboardPartner';
import DashboardMember from '../features/MemberArea/Dashboard/DashboardMember';
import DashboardMemberHome from '../features/MemberArea/Dashboard/Home/Home';
import MemberProfile from '../features/MemberProfile/MemberProfile';
import GeneralInfo from '../features/MemberProfile/GeneralInfo/view';
import FacilityInfoTab from '../features/MemberProfile/FacilityInfo/FacilityInfoTab';
import UsageLog from '../features/MemberProfile/UsageLog/view';
import AccountInfo from '../features/AccountInfo/AccountInfo';
import InsuranceInfo from '../features/AccountInfo/InsuranceInfo/view';
import Reimbursement from '../features/AccountInfo/Reimbursement/Reimbursement';
import BackToPartnersTopbarContent from '../features/Shared/Navigation/BackToPartnersTopbarContent';
import PartnerManagersTab from '../features/PartnerInfo/PartnerManagers/PartnerManagersTab';
import AddPartnerManagerPage from '../features/PartnerInfo/PartnerManagers/AddPartnerManager/AddPartnerManagerPage';
import PartnerManagersList from '../features/PartnerInfo/PartnerManagers/PartnerManagersList/PartnerManagersList';
import PartnerManagerProfilePage from '../features/PartnerManagerProfile/PartnerManagerProfilePage';
import PartnerManagerProfileGeneralInfoTab from '../features/PartnerManagerProfile/GeneralInfo/PartnerManagerProfileGeneralInfoTab';
import ChangePasswordTab from '../features/Shared/ChangePassword/ChangePasswordTab';
import GroupMembershipsPage from '../features/GroupMemberships/GroupMembershipsPage';
import GroupMembershipsGeneralInfoTab from '../features/GroupMemberships/GeneralInfo/GroupMembershipsGeneralInfoTab';
import EmployeeManagementTab from '../features/GroupMemberships/EmployeeManagement/EmployeeManagementTab';
import HCAdminVisitLogTab from '../features/GroupMemberships/VisitLog/HCAdminVisitLogTab';
import PartnerManagerEmployeeManagementTab from '../features/GroupMemberships/EmployeeManagement/PartnerManagerEmployeeManagementTab';
import PartnerManagerVisitLogTab from '../features/GroupMemberships/VisitLog/PartnerManagerVisitLogTab';
import FacilityLocator from '../features/MemberArea/Dashboard/FacilityLocator/FacilityLocator';
import FacilitiesSection from '../features/MemberProfile/FacilityInfo/FacilitiesSection/FacilitiesSection';
import FacilityEnrollmentPage from '../features/MemberProfile/FacilityInfo/FacilityEnrollmentPage/FacilityEnrollmentPage';
import MemberLegalContractPage from '../features/MemberProfile/FacilityInfo/MemberLegalContractPage/MemberLegalContractPage';
import MainLayout from '../layouts/mainLayout';
import AdminArea from '../features/AdminArea/AdminArea';
import PartnerOwnerArea from '../features/PartnerOwnerArea/PartnerOwnerArea';
import PartnerManagerArea from '../features/PartnerManagerArea/PartnerManagerArea';
import MemberArea from '../features/MemberArea/MemberArea';
import WellnessProgramPaymentsPage from '../features/WellnessProgramPayments/WellnessProgramPaymentsPage';
import PartnerOwnerGroupMembershipsPaymentsTab from '../features/GroupMemberships/PartnerGroupMembershipsPayments/PartnerOwnerGroupMembershipsPaymentsTab';
import PartnerManagerGroupMembershipsPaymentsTab from '../features/GroupMemberships/PartnerGroupMembershipsPayments/PartnerManagerGroupMembershipsPaymentsTab';
import PartnerPaymentsTab from '../features/WellnessProgramPayments/PartnerPaymentsTab/PartnerPaymentsTab';
import FacilityPaymentsTab from '../features/WellnessProgramPayments/FacilityPaymentsTab/FacilityPaymentsTab';
import VoucherProgramsPage from '../features/VoucherPrograms/VoucherProgramsPage';
import VoucherProgramsList from '../features/VoucherPrograms/VoucherProgramsList/VoucherProgramsList';
import VoucherProgram from '../features/VoucherPrograms/VoucherProgram/VoucherProgram';
import RecipientsList from '../features/VoucherPrograms/RecipientsList/RecipientsList';
import StaffLegalContractPage from '../features/MemberProfile/FacilityInfo/StaffLegalContractPage/StaffLegalContractPage';
import FacilityManagerArea from '../features/FacilityManagerArea/FacilityManagerArea';
import GuestArea from '../features/App/GuestArea/GuestArea';
import Home from '../features/App/GuestArea/Home/Home';
import ForFacilities from '../features/App/GuestArea/ForFacilities/ForFacilities';
import ForEmployers from '../features/App/GuestArea/ForEmployers/ForEmployers';
import ForInsurers from '../features/App/GuestArea/ForInsurers/ForInsurers';
import ForMembers from '../features/App/GuestArea/ForMembers/ForMembers';
import AboutUs from '../features/App/GuestArea/AboutUs/AboutUs';
import TermsOfUse from '../features/App/GuestArea/TermsOfUse/TermsOfUse';
import PrivacyNotice from '../features/App/GuestArea/PrivacyNotice/PrivacyNotice';
import PrivacyNotice20230824 from '../features/App/GuestArea/PrivacyArchives/PrivacyNotice_20230824';
import PrivacyArchives from '../features/App/GuestArea/PrivacyArchives/PrivacyArchives';
import PrivacyPolicy20230110 from '../features/App/GuestArea/PrivacyArchives/PrivacyPolicy_20230110';
import CaliforniaPrivacyPolicy20230110 from '../features/App/GuestArea/PrivacyArchives/CaliforniaPrivacyPolicy_20230110';
import DataPrivacyRequest from '../features/App/GuestArea/DataPrivacyRequest/DataPrivacyRequest';
import AccessibilityAndMaintenance from '../features/App/GuestArea/AccessibilityAndMaintenance/AccessibilityAndMaintenance';
import FacilityManagerDashboard from '../features/FacilityManagerArea/Dashboard/Dashboard';
import ContactUs from '../features/App/GuestArea/ContactUs/ContactUs';
import NominateClub from '../features/App/GuestArea/NominateClub/NominateClub';
import HealthCheck from '../features/HealthCheck/HealthCheck';
import MembersList from '../features/Members/MembersList';
import MembersTransferResult from '../features/Members/MemberTransfer/MembersTransferResult';
import PrivacyArchiveArea from '../features/App/GuestArea/PrivacyArchives/PrivacyArchiveArea';
import UploadRecipientsResults from '../features/Shared/UploadRecipientsResults/uploadRecipientsResults';
import EmployeeWellness from '../features/App/GuestArea/EmployeeWellness/EmployeeWellness';
import MemberPaymentsTab from '../features/WellnessProgramPayments/MemberPaymentsTab/MemberPaymentsTab';
import BillingInfo from '../features/AccountInfo/BillingInfo/BillingInfoTab';
import Storage from '../features/Shared/Storage/Storage';

export default {
    ...routes.root,
    routes: [
        {
            ...routes.adminArea,
            component: AdminArea,
            authorize: [roles.systemAdmin],
            routes: [
                {
                    ...routes.partners,
                    component: PartnersPage,
                    layout: MainLayout,
                    routes: [
                        { ...routes.partnersList, component: PartnersList },
                        { ...routes.partnersAdd, component: AddPartnerPage }
                    ]
                },
                {
                    ...routes.wellnessProgramPayments,
                    component: WellnessProgramPaymentsPage,
                    layout: MainLayout,
                    routes: [
                        { ...routes.partnerPayments, component: PartnerPaymentsTab },
                        { ...routes.facilityPayments, component: FacilityPaymentsTab },
                        { ...routes.memberPayments, component: MemberPaymentsTab },
                    ]
                },
                {
                    ...routes.adminLegalContractPage,
                    component: StaffLegalContractPage,
                    layout: MainLayout
                },
                {
                    ...routes.members,
                    component: MembersList,
                    layout: MainLayout
                },
                {
                    ...routes.membersTransferResult,
                    component: MembersTransferResult,
                    layout: MainLayout,
                    isHiddenOnUI: true
                }
            ]
        },
        {
            ...routes.partnerOwnerArea,
            component: PartnerOwnerArea,
            authorize: [roles.systemAdmin],
            routes: [
                {
                    ...routes.partnerDashboard,
                    component: DashboardPartner,
                    layout: MainLayout,
                    topbarContent: BackToPartnersTopbarContent,
                },
                {
                    ...routes.partner,
                    component: PartnerInfoPage,
                    layout: MainLayout,
                    topbarContent: BackToPartnersTopbarContent,
                    routes: [
                        { ...routes.partnerGeneral, component: GeneralInfoTab },
                        { ...routes.partnerBankingInfo, component: PartnerBankingInfoTab },
                        { ...routes.partnerBillingInfo, component: PartnerBillingInfoTab },
                        {
                            ...routes.partnerManagers,
                            component: PartnerManagersTab,
                            routes: [
                                {
                                    ...routes.partnerManagersList,
                                    component: PartnerManagersList
                                },
                                {
                                    ...routes.partnerManagersAdd,
                                    component: AddPartnerManagerPage
                                }
                            ]
                        }
                    ]
                },
                {
                    ...routes.groupMemberships,
                    component: GroupMembershipsPage,
                    layout: MainLayout,
                    topbarContent: BackToPartnersTopbarContent,
                    routes: [{
                        ...routes.groupMembershipsGeneral,
                        component: GroupMembershipsGeneralInfoTab
                    }, {
                        ...routes.groupMembershipsEmployeeManagement,
                        component: EmployeeManagementTab
                    },
                    {
                        ...routes.groupMembershipsVisitLog,
                        component: HCAdminVisitLogTab
                    },
                    {
                        ...routes.partnerOwnerGroupMembershipsPayments,
                        component: PartnerOwnerGroupMembershipsPaymentsTab
                    }]
                },
                {
                    ...routes.voucherPrograms,
                    component: VoucherProgramsPage,
                    layout: MainLayout,
                    topbarContent: BackToPartnersTopbarContent,
                    routes: [
                        {
                            ...routes.voucherProgramsList,
                            component: VoucherProgramsList,
                        },
                        {
                            ...routes.voucherProgramsCreate,
                            component: VoucherProgram
                        },
                        {
                            ...routes.voucherProgramsEdit,
                            component: VoucherProgram
                        },
                        {
                            ...routes.voucherProgramRecipientsList,
                            component: RecipientsList
                        },
                    ]
                }
            ],
        },
        {
            ...routes.partnerManagerArea,
            component: PartnerManagerArea,
            authorize: [roles.partnerManager],
            routes: [{
                ...routes.partnerManagerDashboard,
                component: DashboardPartner,
                layout: MainLayout,
            },
            {
                ...routes.partnerManagerProfile,
                component: PartnerManagerProfilePage,
                layout: MainLayout,
                routes: [
                    {
                        ...routes.partnerManagerProfileGeneral,
                        component: PartnerManagerProfileGeneralInfoTab
                    },
                    {
                        ...routes.partnerManagerProfileChangePassword,
                        component: ChangePasswordTab
                    }
                ]
            },
            {
                ...routes.partnerManagerGroupMemberships,
                component: GroupMembershipsPage,
                layout: MainLayout,
                routes: [{
                    ...routes.partnerManagerGroupMembershipsEmployeeManagement,
                    component: PartnerManagerEmployeeManagementTab
                },
                {
                    ...routes.partnerManagerGroupMembershipsVisitLog,
                    component: PartnerManagerVisitLogTab
                },
                {
                    ...routes.partnerManagerGroupMembershipsPayments,
                    component: PartnerManagerGroupMembershipsPaymentsTab
                }]
            }]
        },
        {
            ...routes.memberArea,
            component: MemberArea,
            authorize: [roles.member],
            routes: [{
                ...routes.memberDashboard,
                component: DashboardMember,
                layout: MainLayout,
                routes: [
                    {
                        ...routes.memberDashboardHome,
                        component: DashboardMemberHome
                    },
                    {
                        ...routes.facilityLocator,
                        component: FacilityLocator
                    }]
            },
            {
                ...routes.profile,
                component: MemberProfile,
                layout: MainLayout,
                routes: [
                    {
                        ...routes.general,
                        component: GeneralInfo
                    },
                    {
                        ...routes.facilityInformation,
                        component: FacilityInfoTab,
                        routes: [
                            {
                                ...routes.facilities,
                                component: FacilitiesSection
                            },
                            {
                                ...routes.facilityEnrollmentPage,
                                component: FacilityEnrollmentPage
                            },
                            {
                                ...routes.memberLegalContractPage,
                                component: MemberLegalContractPage
                            }
                        ]
                    },
                    {
                        ...routes.usageLog,
                        component: UsageLog
                    },
                    {
                        ...routes.changePassword,
                        component: ChangePasswordTab
                    }
                ]
            },
            {
                ...routes.account,
                component: AccountInfo,
                layout: MainLayout,
                routes: [
                    {
                        ...routes.insurance,
                        component: InsuranceInfo
                    },
                    {
                        ...routes.billing,
                        component: BillingInfo
                    },
                    {
                        ...routes.reimbursement,
                        component: Reimbursement
                    }
                ]
            }]
        },
        {
            ...routes.facilityManagerArea,
            component: FacilityManagerArea,
            authorize: [roles.facilityManager],
            routes: [
                {
                    ...routes.facilityManagerDashboard,
                    component: FacilityManagerDashboard,
                    layout: MainLayout
                },
                {
                    ...routes.facilityManagerLegalContractPage,
                    component: StaffLegalContractPage,
                    layout: MainLayout
                },
            ]
        },
        {
            ...routes.guestArea,
            component: GuestArea,
            routes: [
                {
                    ...routes.home,
                    component: Home,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.forFacilities,
                    component: ForFacilities,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.forEmployers,
                    component: ForEmployers,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.forInsurers,
                    component: ForInsurers,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.forMembers,
                    component: ForMembers,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.aboutUs,
                    component: AboutUs,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.contactUs,
                    component: ContactUs,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.nominateClub,
                    component: NominateClub,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.employeeWellness,
                    component: EmployeeWellness,
                    layout: MainLayout,
                    hideSidebar: true
                }
            ]
        },
        {
            ...routes.privacynotice,
            component: PrivacyNotice,
            layout: MainLayout,
            hideSidebar: true
        },
        {
            ...routes.consumerhealthprivacynotice,
            component: PrivacyNotice,
            layout: MainLayout,
            hideSidebar: true
        },
        {
            ...routes.privacyarchivearea,
            component: PrivacyArchiveArea,
            routes: [
                {
                    ...routes.privacyarchives,
                    component: PrivacyArchives,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.privacypolicy20230110,
                    component: PrivacyPolicy20230110,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.californiaprivacypolicy20230110,
                    component: CaliforniaPrivacyPolicy20230110,
                    layout: MainLayout,
                    hideSidebar: true
                },
                {
                    ...routes.privacynotice20230824,
                    component: PrivacyNotice20230824,
                    layout: MainLayout,
                    hideSidebar: true
                }
            ]
        },
        {
            ...routes.dataprivacyrequest,
            component: DataPrivacyRequest,
            layout: MainLayout,
            hideSidebar: true
        },
        {
            ...routes.termsofuse,
            component: TermsOfUse,
            layout: MainLayout,
            hideSidebar: true
        },
        {
            ...routes.accessibilityAndMaintenance,
            component: AccessibilityAndMaintenance,
            layout: MainLayout,
            hideSidebar: true
        },
        {
            ...routes.healthCheck,
            component: HealthCheck,
            hideSidebar: true
        },
        {
            ...routes.uploadResults,
            component: UploadRecipientsResults,
            layout: MainLayout,
            hideSidebar: true
        },
        {
            ...routes.storage,
            authorize: [roles.member, roles.systemAdmin, roles.facilityManager],
            component: Storage,
            hideSidebar: true
        }
    ]
};
